import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {
    RouteDefinition, RoutingTransportation, RouteDefinitionWithAlternatives,
    MVGRouteDefinition, MVGRouteDefinitionPart, MVV_TYPE
} from "../interfaces";
import {IRoutable} from "../classes/IRoutable";


@Injectable()
export class MVVRoutingService {
    constructor(private _http: HttpClient) {
    }

    /*
    public static mvgTypeResolve(type: string): MVV_TYPE {
        switch (type) {
            case 's':
                return MVV_TYPE.SBAHN;
            case 'u':
                return MVV_TYPE.UBAHN;
            case 'b':
                return MVV_TYPE.BUS;
            case 't':
                return MVV_TYPE.TRAM;
        }
        return null;
    }

    public static getMvgName(type: MVV_TYPE, label: string): string {
        let name: string = "";
        switch (type) {
            case MVV_TYPE.SBAHN:
                name = "S" + label;
                break;
            case MVV_TYPE.UBAHN:
                name = "U" + label;
                break;
            case MVV_TYPE.BUS:
                name = "Bus " + label;
                break;
            case MVV_TYPE.TRAM:
                name = "Tram " + label;
                break;
        }

        return name;
    }

    private static convertDataToRoutePart(data: any): MVGRouteDefinitionPart {
        if (data['connectionPartType'] == 'FOOTWAY') {
            return {
                transportationType: MVV_TYPE.WALKING,
                transportationName: 'Fußweg',
                transportationDirection: '',
                startTime: new Date(data.departure),
                endTime: new Date(data['arrival']),
                startName: 'Adresse',
                endName: 'Adresse',
                polyline: [],
            };
        } else { // TRANSPORTATION
            let type: MVV_TYPE = MVVRoutingService.mvgTypeResolve(data.product),
                name: string = MVVRoutingService.getMvgName(type, data.label);

            return {
                transportationType: type,
                transportationName: name,
                transportationDirection: '',
                startTime: new Date(data.departure),
                endTime: new Date(data['arrival']),
                startName: data.from.name,
                endName: data.to.name,
                polyline: []
            };
        }
    }

    private static convertDataToRouteDefinition(data: any, from: IRoutable, to: IRoutable): MVGRouteDefinition {
        let hints = [],
            hasPublicTransportation = false,
            points: LatLng[] = [],
            routeParts: MVGRouteDefinitionPart[] = [];

        let partList = data['connectionPartList'];
        if (partList && partList.length > 0) {
            let pos = partList[0].from;
            points.push(new LatLng(pos.latitude, pos.longitude));
            for (let i = 0; i < partList.length; i++) {
                let part = partList[i];
                for (let j = 0; part.path && j < part.path.length; j++) {
                    points.push(new LatLng(part.path[j].latitude, part.path[j].longitude));
                }
                pos = part.to;
                points.push(new LatLng(pos.latitude, pos.longitude));

                routeParts.push(this.convertDataToRoutePart(part));
            }
        }

        return {
            from: from,
            to: to,
            startTime: new Date(data.departure),
            endTime: new Date(data['arrival']),
            start_address: "adresse",
            end_address: "adresse",
            overview_polyline: points,
            length: null,
            duration: Math.floor((data['arrival'] - data.departure) / 1000),
            hints: hints.join(", "),
            hasPublicTransportation: hasPublicTransportation,
            transportationType: RoutingTransportation.MVG,
            routeParts: routeParts,
            inbetweenPlaces: []
        };
    }
    */

    private parsePath(pathStr: string): [number, number][] {
        if (pathStr === undefined) {
            return []; // Z.B. "Gesicherte Verbindung" beim BMW-Museum
        }
        let path: [number, number][] = [];
        let points: string[] = pathStr.split(" ");
        points.forEach((point) => {
            let coords = point.split(",");
            path.push([parseFloat(coords[0]) / 100000, parseFloat(coords[1]) / 100000]);
        });
        return path;
    }

    private parseRouteLeg(leg): MVGRouteDefinitionPart {
        let path: [number, number][] = this.parsePath(leg['path']);
        let interchange: [number, number][] = (leg['interchange'] ? this.parsePath(leg['interchange'].path) : []);

        let product = MVV_TYPE.WALKING;
        switch (leg['mode']['product']) {
            case 'Tram':
                product = MVV_TYPE.TRAM;
                break;
            case 'S-Bahn':
                product = MVV_TYPE.SBAHN;
                break;
            case 'U-Bahn':
                product = MVV_TYPE.UBAHN;
                break;
            case 'MetroBus':
            case 'StadtBus':
            case 'MVV-Regionalbus':
                product = MVV_TYPE.BUS;
                break;
            default:
                if (leg['mode']['type'] == 6) {
                    product = MVV_TYPE.BAHN;
                }
                if (leg['mode']['type'] == 3) {
                    product = MVV_TYPE.BUS;
                }
        }
        let startName = '',
            endName = '',
            startTime = null,
            endTime = null;
        leg['points'].forEach((point) => {
            if (point['usage'] == 'departure') {
                startName = point['name'];
                let d = point['dateTime']['rtDate'].split(".");
                startTime = new Date(d[2] + "-" + d[1] + "-" + d[0] + " " + point['dateTime']['rtTime'] + ':00');
            }
            if (point['usage'] == 'arrival') {
                endName = point['name'];
                let d = point['dateTime']['rtDate'].split(".");
                endTime = new Date(d[2] + "-" + d[1] + "-" + d[0] + " " + point['dateTime']['rtTime'] + ':00');
            }
        });

        return {
            transportationType: product,
            transportationName: leg['mode']['name'],
            transportationDirection: leg['mode']['destination'],
            startTime: startTime,
            endTime: endTime,
            startName: startName,
            endName: endName,
            polyline: path,
            interchangeAfter: interchange,
        }
    }

    parseRoute(data, from: IRoutable, to: IRoutable): MVGRouteDefinition {
        let legs: MVGRouteDefinitionPart[] = [];
        let merged_polyline: [number, number][] = [];

        data['legs'].forEach((legData) => {
            let leg: MVGRouteDefinitionPart = this.parseRouteLeg(legData);
            legs.push(leg);
            leg.polyline.forEach(lnglat => {
                merged_polyline.push(lnglat);
            })
        });
        let duratStr = data['duration'].split(':'),
            duratSec = parseInt(duratStr[0]) * 3600 + parseInt(duratStr[1]) * 60;

        let firstLeg = legs[0],
            lastLeg = legs[legs.length - 1];

        return {
            from: from,
            to: to,
            start_address: firstLeg.startName,
            startTime: firstLeg.startTime,
            endTime: lastLeg.endTime,
            end_address: lastLeg.endName,
            overview_polyline: merged_polyline,
            routeParts: legs,
            duration: duratSec,
            length: parseInt(data['distance']),
            hints: '',
            hasPublicTransportation: true,
            transportationType: RoutingTransportation.MVG,
            inbetweenPlaces: []
        };
    }

    getRoutes(from: IRoutable, to: IRoutable): Observable<RouteDefinitionWithAlternatives> {
        let params = new HttpParams()
            .set('originLat', from.getPosition().lat.toString())
            .set('originLng', from.getPosition().lng.toString())
            .set('destinationLat', to.getPosition().lat.toString())
            .set('destinationLng', to.getPosition().lng.toString());
        let url = 'https://app.muenchen.de/smartcity/mvv_proxy.json';
        return this._http.get(url, {params: params}).pipe(map(data => {
            let allRoutes: RouteDefinition[] = [];
            if (!data['trips']) {
                console.log(data);
                return null;
            }
            if (Array.isArray(data['trips'])) {
                data['trips'].forEach((dat) => {
                    allRoutes.push(this.parseRoute(dat, from, to));
                });
            } else if (data['trips']['trip']) {
                allRoutes.push(this.parseRoute(data['trips']['trip'], from, to));
            }
            return {
                route: allRoutes[0],
                allRoutes: allRoutes
            };
        }));
    }
}

