import {Injectable} from "@angular/core";
import {PlacesEntry} from "../classes/PlacesEntry";
import {AddressAutosuggestEntry} from "../classes/AddressAutosuggestEntry";

export interface LastSearchEntry {
    id: string;
    name: string;
    icon: string;
    lastUsed: number;
    type: string;
    item: any;
}

@Injectable()
export class LastSearchesService {
    public static ENABLED = true;

    private entries: LastSearchEntry[];

    public constructor() {
        if (!LastSearchesService.ENABLED) {
            this.entries = [];
            return;
        }

        let results: LastSearchEntry[] = [];
        try {
            let fromLocal = localStorage.getItem('map_last_searches');
            let parsed = JSON.parse(fromLocal);
            if (parsed) {
                for (let i in parsed) {
                    if (parsed.hasOwnProperty(i) && parsed[i].type == 'place') {
                        let entry = parsed[i];
                        entry.item = PlacesEntry.unserialize(entry.item);
                        results.push(entry);
                    }
                    if (parsed.hasOwnProperty(i) && parsed[i].type == 'address') {
                        let entry = parsed[i];
                        entry.item = AddressAutosuggestEntry.unserialize(entry.item);
                        results.push(entry);
                    }
                }
            }
        } catch (e) {
        }

        this.entries = results;
    }

    public addPlacesEntry(entry: PlacesEntry): void {
        if (!LastSearchesService.ENABLED) {
            return;
        }

        let found = false,
            searchEntry: LastSearchEntry = {
                id: entry.id,
                name: entry.name,
                icon: 'images/poi_.png',
                lastUsed: (new Date()).getTime(),
                type: 'place',
                item: entry
            };

        for (let i = 0; i < this.entries.length; i++) {
            if (this.entries[i].id == entry.id) {
                this.entries[i] = searchEntry;
                found = true;
            }
        }
        if (!found) {
            this.entries.push(searchEntry);
        }

        localStorage.setItem('map_last_searches', JSON.stringify(this.entries));
    }

    public addAddressEntry(address: AddressAutosuggestEntry): void {
        if (!LastSearchesService.ENABLED) {
            return;
        }

        let found = false,
            searchEntry: LastSearchEntry = {
                id: address.id,
                name: address.getShortInfoTitle(),
                icon: 'images/poi_.png',
                lastUsed: (new Date()).getTime(),
                type: 'address',
                item: address
            };

        for (let i = 0; i < this.entries.length; i++) {
            if (this.entries[i].id == address.id) {
                this.entries[i] = searchEntry;
                found = true;
            }
        }
        if (!found) {
            this.entries.push(searchEntry);
        }

        localStorage.setItem('map_last_searches', JSON.stringify(this.entries));
    }

    public getEntries(): LastSearchEntry[] {
        return this.entries;
    }
}
