<div class="accessibility_click_catcher" (click)="close()" *ngIf="show"></div>
<aside id="accessibility-hint" *ngIf="show">
    <button class="btn-link btn-close" type="button" (click)="close()">
        <img src="images/Icon-50px-close.png" alt="Close">
    </button>

    <div class="content">
        <div class="icons">
            <img src="images/accessibility-easy.svg" alt="Plain Language" width="50">
            <img src="images/accessibility-eye.svg" alt="Non-graphical version" width="50">
        </div>
        <p>{{ messages.accessibility_hint_text }}</p>
	      <a href="http://mucinklusiv.de" target="_blank" class="button">
              {{ messages.accessibility_hint_link }}
          </a>
    </div>
</aside>
