import {PlacesDetails} from "./PlacesDetails";

export interface ChargingStation {
    name: string;
    format: string;
    standard: string;
    id: string;
    available: boolean;
}

export class MobilityStationDetails extends PlacesDetails {
    public mobilityEntries: PlacesDetails[];

    private _publicTransport: PlacesDetails = null;
    private _all_bikes: PlacesDetails[] = null;
    private _bikes: PlacesDetails[] = null;
    private _etrikes: PlacesDetails[] = null;
    private _pedelecs: PlacesDetails[] = null;
    private _cars: PlacesDetails[] = null;
    private _chargingPoints: PlacesDetails[] = null;
    private _districtbox: PlacesDetails[] = null;

    public static AREA_OUTLINES: { [id: string]: [number, number][] } = {
        "mv.mobilityMUEN_FREI": [
            [11.586520671844482, 48.16401375843041],
            [11.585179567337036, 48.16353429483512],
            [11.58460557460785, 48.16222827052069],
            [11.58510446548462, 48.16114406617243],
            [11.587582826614378, 48.16115122271191],
            [11.588215827941895, 48.16233203805212],
            [11.587727665901184, 48.163695309228856],
            [11.586520671844482, 48.16401375843041]
        ]
    };

    private initProperties() {
        if (this._bikes !== null) {
            return;
        }

        this._all_bikes = [];
        this._bikes = [];
        this._etrikes = [];
        this._pedelecs = [];
        this._cars = [];
        this._chargingPoints = [];
        this._districtbox = [];

        this.mobilityEntries.forEach((entry) => {
            entry.categories.forEach((cat) => {
                switch (cat.id) {
                    case 'mv.public':
                        this._publicTransport = entry;
                        break;
                    case 'mv.bike':
                        this._bikes.push(entry);
                        this._all_bikes.push(entry);
                        break;
                    case 'mv.etrike':
                        this._etrikes.push(entry);
                        this._all_bikes.push(entry);
                        break;
                    case 'mv.pedelec':
                        this._pedelecs.push(entry);
                        this._all_bikes.push(entry);
                        break;
                    case 'mv.car':
                        this._cars.push(entry);
                        break;
                    case 'mv.charging':
                        this._chargingPoints.push(entry);
                        break;
                    case 'mv.districtbox':
                        this._districtbox.push(entry);
                        break;
                }
            })
        });
    }

    public hasPublicTransport(): boolean {
        this.initProperties();
        return this._publicTransport !== null;
    }

    public hasAnyBike(): boolean {
        this.initProperties();
        return this._all_bikes.length > 0;
    }

    public hasBikes(): boolean {
        this.initProperties();
        return this._bikes.length > 0;
    }

    public hasPedelecs(): boolean {
        this.initProperties();
        return this._pedelecs.length > 0;
    }

    public hasEtrikes(): boolean {
        this.initProperties();
        return this._etrikes.length > 0;
    }

    public hasCars(): boolean {
        this.initProperties();
        return this._cars.length > 0;
    }

    public hasChargingPoints(): boolean {
        this.initProperties();
        return this._chargingPoints.length > 0;
    }

    public hasDistrictbox(): boolean {
        this.initProperties();
        return this._districtbox.length > 0;
    }

    public getPublicTransportStation(): PlacesDetails {
        this.initProperties();
        return this._publicTransport;
    }

    public getStaticBikes(): PlacesDetails[] {
        this.initProperties();
        return this._bikes.filter((bike) => {
            return bike.categories.filter(cat => cat.id === 'mv.bike.station').length > 0;
        });
    }

    public getPedelecs(): PlacesDetails[] {
        this.initProperties();
        return this._pedelecs;
    }

    public getEtrikes(): PlacesDetails[] {
        this.initProperties();
        return this._etrikes;
    }

    public getDistrictbox(): PlacesDetails[] {
        this.initProperties();
        return this._districtbox;
    }

    public getVolatileBikes(): PlacesDetails[] {
        this.initProperties();
        return this._bikes.filter((bike) => {
            return bike.categories.filter(cat => cat.id === 'mv.bike.free').length > 0;
        });
    }

    public getStaticCars(): PlacesDetails[] {
        this.initProperties();
        return this._cars.filter((car) => {
            return car.categories.filter(cat => cat.id === 'mv.car.stattauto').length > 0;
        });
    }

    public getVolatileCars(): PlacesDetails[] {
        this.initProperties();
        return this._cars.filter((car) => {
            return car.categories.filter(cat => cat.id === 'mv.car.stattauto').length === 0;
        });
    }

    public static parseChargingStationPayload(entry: PlacesDetails): ChargingStation[] {
        if (entry.payload === undefined || entry.payload.chargePoints === undefined) {
            return [];
        }

        let stations: ChargingStation[] = [];
        entry.payload.chargePoints.forEach((chargepoint) => {
            if (chargepoint.connectors === undefined) {
                return;
            }
            chargepoint.connectors.forEach((connector) => {
                stations.push({
                    id: chargepoint.id,
                    name: entry.getName(),
                    format: connector.format,
                    standard: connector.standard,
                    available: (chargepoint.status.toLowerCase() === 'available'),
                });
            });
        });
        return stations;
    }

    public getChargingStations(): PlacesDetails[] {
        this.initProperties();
        return this._chargingPoints;
    }
}
