import { Component, OnInit } from '@angular/core';
import { LngLat, LngLatBounds, Map, NavigationControl } from 'mapbox-gl';
import { MapStateService } from "../services/map-state.service";
import { MapMVVLayerService } from "../map-layers/map-mvv.layer.service";
import { MapRoutingLayerService } from "../map-layers/map-routing.layer.service";
import { MapPlacesLayerService } from "../map-layers/map-places.layer.service";
import { MapTilesService } from "../map-layers/map-tiles.service";
import { DEFAULT_ORIENTATION, DEFAULT_PITCH, RoutingMode } from "../interfaces";
import { BikeSharingLayerService } from "../map-layers/map-bikesharing.layer.service";
import { CarSharingLayerService } from "../map-layers/map-carsharing.layer.service";
import { URLService } from "../services/url.service";
import { ChargingStationsLayerService } from "../map-layers/map-charging-stations.layer.service";
import { RoutingService } from "../services/routing.service";
import { AppStateService } from "../services/app-state.service";
import { TaxiLayerService } from "../map-layers/map-taxi.layer.service";
import { GeoFunctions } from '../classes/GeoFunctions';
import { MyLocationLayerService } from '../map-layers/my-location.layer.service';
import { EScooterLayerService } from '../map-layers/map-escooter.layer.service';

@Component({
    selector: 'map-gl',
    templateUrl: './map-gl.component.html',
})
export class MapGlComponent implements OnInit {

    private mapboxgl: any;
    private map: Map;

    private uiPaddingLeft = 30;
    private uiPaddingRight = 30;
    private uiPaddingTop = 180;
    private uiPaddingBottom = 180;

    public zoom_greater_14: boolean = true;
    public zoom_greater_16: boolean = true;

    private mapboxControls: NavigationControl;

    private routingActive: RoutingMode;

    public online: boolean;
    public isLoadingTimeout = true;

    public currBearing = DEFAULT_ORIENTATION;
    public currPitch = DEFAULT_PITCH;

    constructor(private myLocationLayer: MyLocationLayerService,
                private mvvLayer: MapMVVLayerService,
                private routingLayer: MapRoutingLayerService,
                private placesLayer: MapPlacesLayerService,
                private tiles: MapTilesService,
                private bikesharing: BikeSharingLayerService,
                // private escooters: EScooterLayerService,
                // private carsharing: CarSharingLayerService,
                // private charging: ChargingStationsLayerService,
                // private taxis: TaxiLayerService,
                private routingService: RoutingService,
                private urlService: URLService,
                private appState: AppStateService,
                private globalMapActions: MapStateService) {
        this.mapboxgl = require('mapbox-gl');
        this.mapboxControls = new NavigationControl({
            showCompass: true,
            showZoom: true
        });

        this.map = this.mapboxgl.Map;
    }

    public ngOnInit(): void {
        this.buildMap();

        this.myLocationLayer.init(this.map);
        this.tiles.init(this.map);
        this.mvvLayer.init(this.map);
        this.routingLayer.init(this.map);
        this.placesLayer.init(this.map);
        this.bikesharing.init(this.map);
        // this.escooters.init(this.map);
        // this.carsharing.init(this.map);
        // this.charging.init(this.map);
        // this.taxis.init(this.map);

        this.myLocationLayer.startWatching();
    }

    private buildMap(): void {
        this.map = new this.mapboxgl.Map({
            container: 'map-gl',
            pitch: DEFAULT_PITCH,
            zoom: 14.50,
            bearing: DEFAULT_ORIENTATION,
            minZoom: 12,
            maxZoom: 18,
            center: [11.575291, 48.131154],
            attributionControl: false,
            maxBounds: [
                [10.7786393, 47.7584994], // Southwest coordinates
                [12.1340089, 48.6657465]  // Northeast coordinates
            ]
        });

        if (this.urlService.getInitialView()) {
            let initial = this.urlService.getInitialView();
            this.map.setCenter(initial.center);
            this.map.setZoom(initial.zoom);
        } else {
            const center = this.globalMapActions.centerLngLat$.getValue();
            this.map.setCenter(center);
            this.map.setZoom(this.globalMapActions.zoom$.getValue());
        }

        this.initListeners();
        this.triggerMapMovedEvents();
    }

    private initListeners() {
        this.map.on('moveend', this.triggerMapMovedEvents.bind(this));
        this.map.on('resize', this.triggerMapMovedEvents.bind(this));
        this.map.on('dragend', this.triggerMapMovedEvents.bind(this));
        this.map.on('rotateend', this.triggerMapMovedEvents.bind(this));
        this.map.on('pitchend', this.triggerMapMovedEvents.bind(this));

        this.map.on('rotate', () => {
            this.globalMapActions.orientation$.next(this.map.getBearing());
            this.globalMapActions.pitch$.next(this.map.getPitch());
            this.currBearing = this.map.getBearing();
            this.currPitch = this.map.getPitch();
        });

        this.map.on('click', () => {
            if (this.routingActive == RoutingMode.ACTIVE) {
                this.routingService.setFullscreen();
            } else if (this.routingActive == RoutingMode.FULLSCREEN) {
                this.routingService.leaveFullscreen();
            } else {
                this.appState.closePrettyMuchEverything();
            }
        });

        this.routingService.active$.subscribe((active: RoutingMode) => {
            this.routingActive = active;
        });

        this.globalMapActions.gotoBoundsLngLat$.subscribe((bounds: LngLatBounds) => {
            if (bounds) {
                const extBounds = GeoFunctions.extendBoundingboxLngLat(bounds, 0.1);
                this.map.fitBounds(extBounds, {
                    padding: {
                        left: this.uiPaddingLeft,
                        top: this.uiPaddingTop,
                        right: this.uiPaddingRight,
                        bottom: this.uiPaddingBottom
                    },
                    animate: true
                });
            }
        });
        this.globalMapActions.setCenterZoomLngLat$.subscribe((value: { position: LngLat, zoom: number }) => {
            if (value && value.position) {
                this.setViewCorrectedIfNecessary(value.position, value.zoom);
            }
        });

        this.appState.online$.subscribe((online) => {
            this.online = online;
        });

        window.setTimeout(() => {
            this.isLoadingTimeout = false;
        }, 2000);
        this.globalMapActions.myLocationLngLat$.subscribe(pos => {
            if (pos && this.isLoadingTimeout && !this.urlService.hasInitialShortInfo()) {
                this.globalMapActions.triggerSetCenterZoom(pos, null);
            }
        });
    }

    private triggerMapMovedEvents() {
        this.globalMapActions.mapMoved(this.map.getCenter(), this.map.getBounds(), this.map.getZoom());
        this.globalMapActions.triggerMapChange(this.map.getBounds(), this.map.getZoom());

        const zoom = this.map.getZoom();
        this.zoom_greater_14 = (zoom > 14);
        this.zoom_greater_16 = (zoom > 16);
    }

    private setViewCorrectedIfNecessary(point: LngLat, zoom: number) {
        /*
            const extBounds = GeoFunctions.extendBoundingboxLngLat(this.map.getBounds(), 0.5);
            if (!GeoFunctions.boundContains(extBounds, pos.position)) {
                this.map.setCenter(pos.position);
            } else {
                this.setViewCorrectedIfNecessary(pos.position, this.map.getZoom());
            }
         */

        const pos = this.map.project(point);
        if (pos.x < this.uiPaddingLeft || pos.y < this.uiPaddingTop ||
            pos.x > window.innerWidth - this.uiPaddingRight ||
            pos.y > window.innerHeight - this.uiPaddingBottom ||
            this.map.getZoom() !== zoom
        ) {
            if (this.isLoadingTimeout) {
                this.map.setCenter(point);
            } else {
                this.map.flyTo({center: point, zoom});
            }
        }
    }

    public onResetOrientation() {
        this.map.flyTo({
           pitch: DEFAULT_PITCH,
           bearing:DEFAULT_ORIENTATION,
        });
    }

    public getIconTransform() {
        console.log("transform: rotateX(" + this.currPitch + "deg) rotateZ(" + this.currBearing + "deg); "
            + "-webkit-transform: rotateX(" + this.currPitch + "deg) rotateZ(" + this.currBearing + "deg); ");
        return "rotateX(" + this.currPitch + "deg) rotateZ(" + this.currBearing + "deg)";
    }
}
