<aside id="sidebar">
    <div class="sidebar-section logo">
        <button class="btn-link btn-close" type="button" (click)="closeSidebar()">
            <img src="images/Icon-50px-close.png" alt="Close">
        </button>
        <img src="images/logo_lhm_en.png" alt="Landeshauptstadt München" width="170" *ngIf="language == 'en'">
        <img src="images/logo_lhm.svg" alt="Landeshauptstadt München" width="170" *ngIf="language == 'de'">
        <div class="simply-logo">
            <a href="https://www.munich.travel/en-gb" target="_blank" *ngIf="language == 'en'">
                <img src="images/simply_muenchen.jpg" alt="Simply Munich">
            </a>
            <a href="https://www.muenchen.travel/" target="_blank" *ngIf="language == 'de'">
                <img src="images/einfach-muenchen-logo.jpg" alt="Einfach München">
            </a>
        </div>
    </div>
    <div class="sidebar-section language_chooser">
        <div class="language" (click)="setLanguage('de')" [class.active]="language == 'de'">
            <img src="images/flag-de.svg" alt="DE" width="30">
        </div>
        <div class="language" (click)="setLanguage('en')" [class.active]="language == 'en'">
            <img src="images/flag-uk.svg" alt="EN" width="30">
        </div>
    </div>
    <div class="sidebar-section tiles_chooser">
        <h2>{{ messages.title_tiles }}</h2>
        <ul>
            <li class="tile radio-row" (click)="selectTiles(TILE_TYPES.MAPBOX_MTOURISMUS)" [class.active]="tiles == TILE_TYPES.MAPBOX_MTOURISMUS">
                {{ messages.tile_basis }}
                <span class="active-indicator"></span>
            </li>
            <li class="tile radio-row" (click)="selectTiles(TILE_TYPES.SWM_LUFTBILD)" [class.active]="tiles == TILE_TYPES.SWM_LUFTBILD">
                {{ messages.tile_luftbild }}
                <span class="active-indicator"></span>
            </li>
        </ul>
    </div>
    <section class="sidebar-section options_chooser">
        <h2>{{ messages.title_options }}</h2>
        <ul>
            <li class="checkmark-row mvv" (click)="togglePublicActive()" [class.active]="publicActive">
                {{ messages.layer_mvv }}
                <span class="active-indicator"></span>
            </li>
            <li class="checkmark-row mvgbike" (click)="toggleMvgBikeActive()" [class.active]="bikeActive">
                {{ messages.layer_rad }}
                <span class="active-indicator"></span>
            </li>
        </ul>
        </section>
    <section class="sidebar-section links">
        <h2>Links</h2>
        <ul>
            <li><a href="https://www.muenchen.travel/" target="_blank" rel="noopener">{{ messages.muc_tourism }}</a></li>
            <li><a href="https://www.muenchen.de/" target="_blank" rel="noopener">{{ messages.muc_portal }}</a></li>
        </ul>
    </section>
    <section class="sidebar-section links">
        <h2>{{ messages.accessible }}</h2>
        <ul>
            <li><a href="http://www.mucinklusiv.de/" target="_blank" rel="noopener">{{ messages.stele_accessible }}</a></li>
            <li><a href="http://www.muenchen-tourismus-barrierefrei.de/de/" target="_blank" rel="noopener">{{ messages.tourism_accessible }}</a></li>
        </ul>
    </section>
    <section class="sidebar-section links">
        <ul>
            <li><a href="https://www.muenchen.travel/wichtige-links/impressum" target="_blank" rel="noopener" *ngIf="language == 'de'">{{ messages.impressum }}</a></li>
            <li><a href="https://www.munich.travel/en/important-links/legal-note" target="_blank" rel="noopener" *ngIf="language == 'en'">{{ messages.impressum }}</a></li>
            <li><a href="tos/datenschutz.html" target="_blank" rel="noopener">{{ messages.privacy }}</a></li>
        </ul>
    </section>
    <!--
    <div class="debug_stats">
        Zoom: {{ mapZoom | async }}<br>
        Center: {{ (mapCenter | async)?.lat }},{{ (mapCenter | async)?.lng }}
    </div>
    -->
</aside>
<div class="sidebar_click_catcher" (click)="closeSidebar()" (swiperight)="closeSidebar()"></div>
