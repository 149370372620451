<form method="get" class="routing-form" [class.visible]="formVisible" (submit)="$event.preventDefault()">
    <header>
        <button class="close-button btn btn-link" (click)="closeAndGotoShortInfo()">
            <img src="images/Icon-Schliessen.svg" alt="Schließen">
        </button>
        <h2>{{ messages.title }}</h2>
    </header>
    <div class="routing-from-to">
        <div class="routing-field">
            <label for="routeFromTitle">{{ messages.from }}:</label>
            <div>
                <routing-typeahead [(routable)]="routeFrom" (routableChange)="routableFromChanged()"
                                   [customLocation]="customRoutingLocation" id="routeFromTitle"
                                   [placeholder]="messages.from_placeholder"
                ></routing-typeahead>
            </div>
        </div>
        <div class="routing-field">
            <label for="routeToTitle">{{ messages.to }}:</label>
            <div>
                <routing-typeahead [(routable)]="routeTo" (routableChange)="routableToChanged()"
                                   [customLocation]="customRoutingLocation" id="routeToTitle"
                                   [placeholder]="messages.to_placeholder"
                ></routing-typeahead>
            </div>
        </div>
        <button class="btn btn-link switch-from-to" type="button" (click)="switchFromTo()">
            <img src="images/Icon-route-umkehren1.svg" alt="Umkehren">
        </button>
    </div>
    <div class="routes-loading" *ngIf="routesLoading">
        {{ messages.calculating_route }}
    </div>
    <routing-switcher></routing-switcher>
    <button type="button" class="btn btn-block btn-show-route" (click)="showRouteFullscreen()">
        {{ messages.show_route }}
    </button>
</form>

<div class="routing-switcher" [class.visible]="switcherVisible">
    <header>
        <button class="close-button btn btn-link" (click)="closeAndGotoShortInfo()">
            <img src="images/Icon-Schliessen.svg" alt="Schließen">
        </button>
        <h2>{{ messages.to }}: {{ routeTo?.getRoutingTitle(language) }}</h2>
        <div class="form-opener-holder">
            {{ messages.from }}:
            <button class="form-opener btn btn-link" type="button" (click)="openForm()">
                {{ routeFrom?.getRoutingTitle(language) }}
            </button>
        </div>
    </header>
    <div class="routes-loading" *ngIf="routesLoading">
        {{ messages.calculating_route }}
    </div>

    <routing-switcher></routing-switcher>
</div>

<aside class="routing-legal-line" *ngIf="legalLineMvv">
    Quelle: <a href="https://www.mvv-muenchen.de/" target="_blank" rel="noopener">MVV GmbH</a> auf Datenbasis von PTV AG/HERE
</aside>

<!--
<aside *ngIf="!activeMVGRoute" class="routing-information" [class.visible]="bottomViewShown > 0">
    <div class="distance-time" [innerHtml]="infoDistanceTimeHtml"></div>
</aside>
<aside class="routing-information"
       [class.routing-information-mvg]="activeMVGRoute"
       [class.visible]="bottomViewShown > 0 && activeMVGRoute" [class.expanded]="bottomViewShown > 1 && activeMVGRoute">

    <div class="small-version" *ngIf="!activeMVGRoute">
        <div class="distance-time" [innerHtml]="infoDistanceTimeHtml"></div>
    </div>

    <div class="small-version clickable" (click)="expandMvgRoutes()" *ngIf="activeMVGRoute">
        <div class="distance-time">{{ activeMVGRoute.duration | duration }}</div>
        <mvg-routing-transportations [route]="activeMVGRoute"></mvg-routing-transportations>
        <div class="departure-time">{{ messages.departure }}: {{ activeMVGRoute.startTime | date:"HH:mm" }}</div>
    </div>
    <div class="big-version" *ngIf="activeMVGRoute">
        <h2>{{ messages.public_title }}</h2>
        <ol class="connection-list">
            <li *ngFor="let connection of allMvgRoutes; let i = index" [class.opened]="openedConnection===i">
                <div class="summary" (click)="toggleOpenedConnection(i)">
                    <div class="time">
                        {{ connection.startTime | date:"HH:mm" }}
                    </div>
                    <div class="transports">
                        <mvg-routing-transportations [route]="connection"></mvg-routing-transportations>
                    </div>
                    <div class="duration">
                        {{ connection.duration | duration }}
                    </div>
                </div>
                <div class="details parts{{ connection.routeParts.length }}"
                     (click)="selectAlternativeMvgRoute(connection)">
                    <ol class="detailed-transportation-list">
                        <li *ngFor="let part of connection.routeParts">
                            <div class="icon">
                                <img *ngIf="part.transportationType == mvgTypes.WALKING"
                                     src="images/routing-walking.png" alt="Fußweg" class="transport-icon">
                                <img *ngIf="part.transportationType == mvgTypes.U" src="images/u-bahn.png" alt="U-Bahn"
                                     class="transport-icon">
                                <img *ngIf="part.transportationType == mvgTypes.S" src="images/s-bahn.png" alt="S-Bahn"
                                     class="transport-icon">
                                <img *ngIf="part.transportationType == mvgTypes.TRAM" src="images/tram.png" alt="Tram"
                                     class="transport-icon">
                                <img *ngIf="part.transportationType == mvgTypes.BUS" src="images/bus.png" alt="Bus"
                                     class="transport-icon">
                            </div>
                            <div class="transport">
                                {{ part.transportationName }}
                            </div>
                            <div class="time">
                                {{ part.startTime | date:"HH:mm" }} - {{ part.endTime | date:"HH:mm" }} Uhr
                            </div>
                        </li>
                    </ol>
                </div>
            </li>
        </ol>
    </div>
</aside>
-->