<div class="search-bar" *ngIf="!hidden">
    <div class="search-holder">
        <div class="input-group input-group-lg">
            <input [(ngModel)]="asyncSelected"
                   [typeahead]="dataSource"
                   (typeaheadLoading)="changeTypeaheadLoading($event)"
                   (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                   (typeaheadOnSelect)="typeaheadOnSelect($event)"
                   [typeaheadOptionsLimit]="10"
                   [typeaheadOptionField]="'name'"
                   [typeaheadMinLength]="0"
                   [typeaheadPlacement]="typeaheadPlacement"
                   [placeholder]="messages.placeholder"
                   class="form-control">
                <button class="typeahead-clear-button" (click)="clearInput()"
                        *ngIf="typeaheadLoading===false && asyncSelected !== ''">
                    <span class="glyphicon glyphicon-remove"></span>
                </button>
        </div>

        <div *ngIf="typeaheadLoading===true" class="typeahead-loading-indicator z-index-search-bar">
            <i class="glyphicon glyphicon-refresh ng-hide" style=""></i>
        </div>
        <div *ngIf="typeaheadNoResults===true" class="typeahead-nothing-found z-index-search-bar" style="">
            <i class="glyphicon glyphicon-remove"></i> {{ messages.none }}
        </div>
    </div>

    <div class="goto-current-pos-holder">
        <button class="btn btn-default" type="button" (click)="onGotoCurrentPosition()">
            <img src="images/Icon-Fadenkreuz.png" alt="Meine Position" class="icon">
        </button>
    </div>

    <div class="toggle-sidebar-holder">
        <button class="btn btn-default" type="button" (click)="toggleSidebar()">
            <img src="images/Icon-Menu.png" alt="Sidebar" class="icon">
        </button>
    </div>
</div>
