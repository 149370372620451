<ul class="dropdown-menu"
    [ngStyle]="{top: top, left: left, display: 'block'}"
    (mouseleave)="focusLost()">
    <li *ngIf="categories.length > 0" class="dropdown-header">In der Nähe</li>
    <li *ngIf="categories.length > 0" class="categories">
        <ul>
            <ng-template ngFor let-cat let-i="index" [ngForOf]="categories">
                <li><a href="#" (click)="selectMatch(cat, $event)" tabindex="-1" [class.active]="isActive(cat)" (mouseenter)="selectActive(cat)">
                    <div class="icon">
                        <img [src]="cat.item.icon" alt="" width="22" height="22">
                    </div>
                    <h3>{{ cat.item.name }}</h3>
                </a></li>
            </ng-template>
        </ul>
    </li>
    <ng-template ngFor let-match let-i="index" [ngForOf]="matches">
        <li *ngIf="match.isHeader()" class="dropdown-header">{{match}}</li>
        <li *ngIf="!match.isHeader()" class="entry" [class.active]="isActive(match)" (mouseenter)="selectActive(match)">
            <a href="#" (click)="selectMatch(match, $event)" tabindex="-1">
                <div class="entry"
                     *ngIf="match.item.type == 'entry' || (match.item.type == 'last-search' && match.item.item.getClassType() != 'address')">
                    <h3>{{ match }}</h3>
                    <div class="address" *ngIf="match.item.item !== undefined && match.item.item.city == 'München'">
                        {{ match.item.item.street }} {{ match.item.item.streetNumber }}
                    </div>
                    <div class="address" *ngIf="match.item.item !== undefined && match.item.item.city && match.item.item.city != 'München'">
                        {{ match.item.item.street }} {{ match.item.item.streetNumber }}, {{ match.item.item.city }}
                    </div>
                </div>
                <div class="routing-std" *ngIf="match.item.type == 'routing-std'">
                    {{ match }}
                </div>
                <div class="address"
                     *ngIf="match.item.type == 'address-autosuggest' || (match.item.type == 'last-search' && match.item.item.getClassType() == 'address')">
                    <h3>{{ match.item.name }}</h3>
                </div>
            </a>
        </li>
    </ng-template>
</ul>
