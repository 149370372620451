import {Injectable} from "@angular/core";
import {IShortInfoComponentContent} from "../classes/IShortInfoComponentContent";
import {EpBoundingboxLoaderService} from "../services/ep-boundingbox-loader.service";
import {PlacesEntry} from "../classes/PlacesEntry";
import {MarkerCollectionStore} from "../classes/MarkerCollectionStore";
import {RoutingService} from "../services/routing.service";
import {RoutingMode} from "../interfaces";
import {MVGStateService} from "../services/mvg-state.service";
import {MapStateService} from "../services/map-state.service";
import {AppStateService} from "../services/app-state.service";
import { Marker, Map, MarkerOptions } from 'mapbox-gl';

const MIN_ZOOM = 15;
// const MOBILITY_MIN_ZOOM = 14; // Synch map-carsharing, map-bikesharing and map-mvv
// const MOBILITY_ALL_MIN_ZOOM = 17; // Synch map-carsharing, map-bikesharing and map-mvv

@Injectable()
export class BikeSharingLayerService {
    private map: Map;
    private foundBikes: PlacesEntry[] = [];
    private markerCollection: MarkerCollectionStore<PlacesEntry> = null;
    private markers: { [id: string]: Marker } = {};
    private bikeActiveData: PlacesEntry = null;
    // private Z_INDEX_OFFSET: 0;

    constructor(private _mapState: MapStateService,
                private _appState: AppStateService,
                private _routingService: RoutingService,
                private _mvgState: MVGStateService,
                private _epBBLoader: EpBoundingboxLoaderService) {
    }

    public init(map: Map) {
        this.map = map;

        this.markerCollection = new MarkerCollectionStore<PlacesEntry>(
            this.addInactiveMarker.bind(this),
            this.delMarker.bind(this),
            this.addActiveMarker.bind(this),
            this.delMarker.bind(this),
        );

        this._appState.shortInfoComponentActive$.subscribe((shortInfoComponentActive: IShortInfoComponentContent) => {
            if (shortInfoComponentActive && shortInfoComponentActive.getClassType() == 'bike') {
                this.bikeActiveData = <PlacesEntry> shortInfoComponentActive;
            } else {
                this.bikeActiveData = null;
            }
            this.markerCollection.setActiveElement(this.bikeActiveData);
        });

        this._epBBLoader.setListenerCategories('bikes', []).subscribe((data) => {
            this.foundBikes = data.places;
            this.showCurrentMarkers();
            this._appState.bikesReloaded(this.foundBikes);
        });

        this._mapState.zoom$.subscribe(this.setLoaderListener.bind(this));
        this._mvgState.bikeActive$.subscribe(this.setLoaderListener.bind(this));

        this._mvgState.bikeActive$.subscribe(this.showCurrentMarkers.bind(this));

        this._routingService.active$.subscribe(this.showCurrentMarkers.bind(this));
        this._routingService.from$.subscribe(this.showCurrentMarkers.bind(this));
        this._routingService.to$.subscribe(this.showCurrentMarkers.bind(this));
    }

    private setLoaderListener() {
        if (this._mvgState.bikeActive$.getValue()) {
            if (this._mapState.zoom$.getValue() >= MIN_ZOOM) {
                this._epBBLoader.setListenerCategories('bikes', ['mv.bike', 'mv.pedelec', 'mv.etrike'])
            } else {
                this._epBBLoader.setListenerCategories('bikes', [])
            }
        } else {
            this._epBBLoader.setListenerCategories('bikes', [])
        }
    }

    public delMarker(station: PlacesEntry) {
        if (this.markers[station.id]) {
            this.markers[station.id].remove();
            delete(this.markers[station.id]);
        }
    }

    public addInactiveMarker(bike: PlacesEntry) {
        const opts: MarkerOptions = {},
            icon = document.createElement('img');

        opts.element = icon;
        opts.draggable = false;
        opts.anchor = 'center';
        icon.classList.add('bike-marker');
        icon.classList.add('inactive');
        icon.style.width = '24px';
        icon.style.height = '24px';

        icon.src = 'images/pt_mvg_rad_freefloater.png';
        for (let i in bike.categories) {
            if (bike.categories[i].id == 'mv.bike.station.available') {
                icon.src = 'images/mvg_rad_station.svg';
            }
            if (bike.categories[i].id == 'mv.bike.station.notavailable') {
                icon.src = 'images/mvg_rad_station_leer.svg';
            }

            if (bike.categories[i].id == 'mv.etrike.station.available') {
                icon.src = 'images/mvg_rad_station.svg';
            }
            if (bike.categories[i].id == 'mv.etrike.station.notavailable') {
                icon.src = 'images/mvg_rad_station_leer.svg';
            }

            if (bike.categories[i].id == 'mv.pedelec.station.available') {
                icon.src = 'images/mvg_rad_station.svg';
            }
            if (bike.categories[i].id == 'mv.bike.station.notavailable') {
                icon.src = 'images/mvg_rad_station_leer.svg';
            }
        }

        icon.addEventListener('click', ev => {
            this._appState.openPlaceInShortInfo(bike);
            ev.stopPropagation();
        });

        const marker = new Marker(opts);
        marker.setLngLat(bike.position);
        marker.addTo(this.map);
        this.markers[bike.id] = marker;
    }

    public addActiveMarker(bike: PlacesEntry) {
        const opts: MarkerOptions = {},
            icon = document.createElement('img');

        opts.element = icon;
        opts.draggable = false;
        opts.anchor = 'bottom';
        icon.classList.add('bike-marker');
        icon.classList.add('active');
        icon.style.width = '36px';
        icon.style.height = '46px';

        icon.src = 'images/pt_mvg_rad_freefloater_selected.png';
        for (let i in bike.categories) {
            if (bike.categories[i].id == 'mv.bike.station.available') {
                icon.src = 'images/mvg_rad_station_selected.svg';
            }
            if (bike.categories[i].id == 'mv.bike.station.notavailable') {
                icon.src = 'images/mvg_rad_station_leer_selected.svg';
            }

            if (bike.categories[i].id == 'mv.etrike.station.available') {
                icon.src = 'images/mvg_rad_station_selected.svg';
            }
            if (bike.categories[i].id == 'mv.etrike.station.notavailable') {
                icon.src = 'images/mvg_rad_station_leer_selected.svg';
            }

            if (bike.categories[i].id == 'mv.pedelec.station.available') {
                icon.src = 'images/mvg_rad_station_selected.svg';
            }
            if (bike.categories[i].id == 'mv.bike.station.notavailable') {
                icon.src = 'images/mvg_rad_station_leer_selected.svg';
            }
        }


        const marker = new Marker(opts);
        marker.setLngLat(bike.position);
        marker.addTo(this.map);
        this.markers[bike.id] = marker;
    }

    private showCurrentMarkers() {
        let active: boolean = this._mvgState.bikeActive$.getValue(),
            zoom = this.map.getZoom();

        let collection: PlacesEntry[] = [];
        if (this._routingService.active$.getValue() !== RoutingMode.NONE) {
            let from = this._routingService.from$.getValue();
            let to = this._routingService.to$.getValue();
            if (from && from.getClassType() == 'bike') {
                collection.push(<PlacesEntry>from);
            }
            if (to && to.getClassType() == 'bike') {
                collection.push(<PlacesEntry>to);
            }
        } else {
            if (active && zoom >= MIN_ZOOM) {
                collection = this.foundBikes;
            }
        }
        if (this.bikeActiveData && collection.filter(place => place.id == this.bikeActiveData.id).length === 0) {
            collection.push(this.bikeActiveData);
        }
        this.markerCollection.setNewElementCollection(collection);
    }
}
