import { Injectable } from "@angular/core";
import { LngLat, Map, Marker } from 'mapbox-gl';
import { MapStateService } from '../services/map-state.service';


@Injectable()
export class MyLocationLayerService {
    private map: Map;

    private _dotElement: HTMLElement;
    private _userLocationDotMarker: Marker;
    private watchId: number = null;

    public constructor(private globalMapActions: MapStateService) {
    }

    public init(map: Map) {
        this.map = map;

        this._dotElement = document.createElement('div');
        this._dotElement.classList.add('mapboxgl-user-location-dot');
        this._userLocationDotMarker = new Marker(this._dotElement);

        this.globalMapActions.myLocationLngLat$.subscribe(pos => {
            if (pos) {
                this._userLocationDotMarker.setLngLat([pos.lng, pos.lat]).addTo(this.map);
            } else {
                this._userLocationDotMarker.remove();
            }
        });
        this.globalMapActions.gotoMyPos$.subscribe(this.triggerGoTo.bind(this));
    }

    public triggerGoTo() {
        window.navigator.geolocation.getCurrentPosition((event: Position) => {
            console.log('### geolocate', event);
            const pos = new LngLat(event.coords.longitude, event.coords.latitude);
            this.globalMapActions.updateMyLocation(pos, event.coords.accuracy);
            this.globalMapActions.triggerSetCenterZoom(pos, null);
        }, () => {
            console.log('### geolocate error', event);
            this.globalMapActions.updateMyLocation(null, null);
        }, {
            enableHighAccuracy: true,
            maximumAge: 0,
            timeout: 10000
        });
    }

    public startWatching() {
        if (this.watchId) {
            return;
        }
        this.watchId = window.navigator.geolocation.watchPosition((event: Position) => {
            console.log('### geolocate (watch)', event);
            const pos = new LngLat(event.coords.longitude, event.coords.latitude);
            this.globalMapActions.updateMyLocation(pos, event.coords.accuracy);
        }, () => {
            console.log('### geolocate (watch) error', event);
            this.watchId = null;
            this.globalMapActions.updateMyLocation(null, null);
        }, {
            enableHighAccuracy: false,
            maximumAge: 0,
            timeout: 10000
        });
    }
}
