import {AfterViewInit, Component, EventEmitter, Output} from "@angular/core";
import {
    ALL_ROUTING_TRANSPORTATIONS,
    MVGRouteDefinition,
    RouteDefinition,
    RoutingTransportation
} from "../interfaces";
import {IRoutable} from "../classes/IRoutable";
import {Messages, RoutingMessages} from "../messages/messages";
import {MapStateService} from "../services/map-state.service";
import {Routing} from "../classes/Routing";
import {RoutingService} from "../services/routing.service";
import {AppStateService} from "../services/app-state.service";
import { GeoFunctions } from '../classes/GeoFunctions';

@Component({
    selector: 'routing-switcher',
    templateUrl: 'routing-switcher.component.html',
    styles: [],
})
export class RoutingSwitcherComponent implements AfterViewInit {
    @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();

    public formattedRouteTimes: { [transport: number]: string } = {};

    public transportation: RoutingTransportation;
    public allTransportationRoutes: { [transport: number]: RouteDefinition };
    public activeMVGRoute: MVGRouteDefinition = null;
    public allMvgRoutes: MVGRouteDefinition[];
    public routesLoaded: boolean = false;
    public routesLoading: boolean = false;
    public routeFrom: IRoutable = null;
    public routeTo: IRoutable = null;

    public messages: RoutingMessages;
    public language: string = 'de';

    constructor(private _routingService: RoutingService,
                private _globalMapActions: MapStateService,
                private _appState: AppStateService) {
        this._appState.language$.subscribe((messages: Messages) => {
            this.messages = messages.routing;
            this.language = messages.language;
        });
    }

    /* Called from the UI */
    public setTransportation(transportation: RoutingTransportation) {
        this._routingService.setTransportation(transportation, true, true);
        this.change.emit();
    }

    public currentlyAvailableRoutings() {
        let distance = 0;
        if (this.routeTo && this.routeFrom) {
            distance = GeoFunctions.getDistance(this.routeFrom.getPosition(), this.routeTo.getPosition());
        }
        let available = [];
        Routing.AVAILABLE_ROUTINGS.forEach((routing) => {
            if (routing.id == RoutingTransportation.WALKING && distance > 8000) {
                return;
            }
            if (routing.id == RoutingTransportation.SIGHTSEEING && distance > 8000) {
                return;
            }
            if (routing.id == RoutingTransportation.CAR && distance < 8000) {
                return;
            }
            available.push(routing);
        });
        return available;
    }

    public formatDuration(seconds: number) {
        let h = Math.floor(seconds / 3600),
            m = Math.floor((seconds - (h * 3600)) / 60),
            m2 = (m < 10 ? "0" + m.toString() : m.toString());

        if (h > 0) {
            if (this.language == 'de') {
                return h + ':' + m2 + ' Std.';
            } else {
                return h + ':' + m2 + ' h';
            }
        } else {
            if (this.language == 'de') {
                return m2 + ' Min.';
            } else {
                return m2 + ' min.';
            }
        }
    }

    public static formatLength(meter: number) {
        let km: number = Math.floor(meter / 1000);
        return km + "," + Math.floor((meter - km * 1000) / 100) + "km";
    }

    private setRoutingInfo(routes: { [transport: number]: RouteDefinition }) {
        this.allTransportationRoutes = routes;
        if (routes && Object.keys(routes).length > 0) {
            this.routesLoaded = true;
            this.routesLoading = false;
        } else if (this.routeFrom == null || this.routeTo == null) {
            this.routesLoaded = true;
            this.routesLoading = false;
        } else if (this.routeFrom.getId() == this.routeTo.getId()) {
            this.routesLoaded = true;
            this.routesLoading = false;
        } else {
            this.routesLoaded = false;
            this.routesLoading = true;
        }

        window.setTimeout(() => {
            let newTimes = {};
            ALL_ROUTING_TRANSPORTATIONS.forEach((transport: RoutingTransportation) => {
                if (routes && routes[transport]) {
                    newTimes[transport] = this.formatDuration(routes[transport].duration);
                } else {
                    newTimes[transport] = "";
                }
            });
            this.formattedRouteTimes = newTimes;
        }, 0);
    }

    ngAfterViewInit() {
        this.transportation = this._routingService.transportation$.getValue();
        this._routingService.transportation$.subscribe((trans: RoutingTransportation) => {
            this.transportation = trans;
        });
        this._routingService.allMvgRoutes$.subscribe((routes: MVGRouteDefinition[]) => {
            this.allMvgRoutes = routes;
        });
        this._routingService.from$.subscribe((from) => {
            this.routeFrom = from;
        });
        this._routingService.to$.subscribe((to)=> {
            this.routeTo = to;
        });
        this._routingService.allTransportationRoutes$.subscribe(this.setRoutingInfo.bind(this));
    }

    getMvgRoutingLink() {
        if (this.routeFrom && this.routeTo) {
            const from = encodeURIComponent(this.routeFrom.getMvgRoutingString());
            const to = encodeURIComponent(this.routeTo.getMvgRoutingString());
            return 'mvg-routing-redirect.php?from=' + from + "&to=" + to;
        } else {
            return 'https://mvg.de/';
        }
    }
}
