import {Injectable} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {map} from "rxjs/operators";
import {BuildingWithPolygon} from "../interfaces";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class MuenchenBuildingsService {
    private backend_url = 'https://app.muenchen.de/img/marker/buildings-geojson';

    public static HALF_TRANSPARENT_ZOOM_LEVEL = 18;

    constructor(private http: HttpClient) {
    }

    public getBuildings(): Observable<BuildingWithPolygon[]> {
        return this.http.get(this.backend_url).pipe(map((body: Array<any>) => {
            let buildings: BuildingWithPolygon[] = [];
            body['features'].forEach((feature) => {
                buildings.push({
                    placeId: feature.properties.ep_id,
                    name: feature.properties.title,
                    polygon: feature.geometry.coordinates
                });
            });
            return buildings;
        }));
    }
}
