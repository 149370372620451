import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {SettingsService} from "./settings.service";

@Injectable()
export class MVGStateService {
    constructor(
        private _settings: SettingsService) {
    }

    public publicTransportActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public bikeActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public escooterActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public carsharingActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public chargingStationsActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public mobilityStationActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public taxiActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public setBikeActive(active: boolean, setSettings: boolean = true) {
        this.bikeActive$.next(active);
        if (setSettings) {
            this._settings.setBikeActive(active);
        }
    }

    public toggleBikeActive(setSettings: boolean = true) {
        let newActive = !this.bikeActive$.getValue();
        this.setBikeActive(newActive);
        if (setSettings) {
            this._settings.setBikeActive(newActive);
        }
    }

    public setEScooterActive(active: boolean, setSettings: boolean = true) {
        this.escooterActive$.next(active);
        if (setSettings) {
            this._settings.setEScooterActive(active);
        }
    }

    public setCarsharingActive(active: boolean, setSettings: boolean = true) {
        this.carsharingActive$.next(active);
        if (setSettings) {
            this._settings.setCarActive(active);
        }
    }

    public toggleCarActive(setSettings: boolean = true) {
        let newActive = !this.carsharingActive$.getValue();
        this.setCarsharingActive(newActive);
        if (setSettings) {
            this._settings.setCarActive(newActive);
        }
    }

    public setChargingStationActive(active: boolean, setSettings: boolean = true) {
        console.log('Set: active');
        this.chargingStationsActive$.next(active);
        if (setSettings) {
            this._settings.setChargingStationsActive(active);
        }
    }

    public toggleChargingStationActive(setSettings: boolean = true) {
        let newActive = !this.chargingStationsActive$.getValue();
        this.setChargingStationActive(newActive);
        if (setSettings) {
            this._settings.setChargingStationsActive(newActive);
        }
    }

    public setPublicTransportActive(active: boolean, setSettings: boolean = true) {
        this.publicTransportActive$.next(active);
        if (setSettings) {
            this._settings.setPublicTransportActive(active);
        }
    }

    public togglePublicTransportActive(setSettings: boolean = true) {
        let newActive = !this.publicTransportActive$.getValue();
        this.setPublicTransportActive(newActive);
        if (setSettings) {
            this._settings.setPublicTransportActive(newActive);
        }
    }

    public setMobilityStationActive(active: boolean) {
        this.mobilityStationActive$.next(active);
    }

    public toggleMobilityStationActive() {
        let newActive = !this.mobilityStationActive$.getValue();
        this.setMobilityStationActive(newActive);
    }

    public setTaxiActive(active: boolean) {
        this.taxiActive$.next(active);
    }
}
