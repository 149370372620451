import { Injectable } from "@angular/core";
import {
    MVGRouteDefinition, MVGRouteDefinitionPart, MVV_TYPE, RouteDefinition, RoutingMode,
    RoutingTransportation
} from "../interfaces";
import { IShortInfoComponentContent } from "../classes/IShortInfoComponentContent";
import { AddressAutosuggestEntry } from "../classes/AddressAutosuggestEntry";
import { PlacesEntry } from "../classes/PlacesEntry";
import { IRoutable } from "../classes/IRoutable";
import { RoutingService } from "../services/routing.service";
import { MapStateService } from "../services/map-state.service";
import { AppStateService } from "../services/app-state.service";
import { Map, Marker, MarkerOptions } from "mapbox-gl";

@Injectable()
export class MapRoutingLayerService {
    private map: Map;
    private route: RouteDefinition = null;
    private active: RoutingMode = RoutingMode.NONE;
    private currentRoutePolyline: string = null;
    private activeAddress: AddressAutosuggestEntry = null;
    private activeAddressMarker: Marker = null;
    private inbetweenMarkers: { [id: string]: Marker } = {};
    private placeholderMarkers: Marker[] = [];
    private routingMarkers: Marker[] = [];

    private lineOptionsWalking = {
        color: '#C56428', // MVG-Gelb
        // dashArray: '5, 5',
        // weight: 2,
    };

    public constructor(private _routingService: RoutingService,
                       private _mapState: MapStateService,
                       private _appState: AppStateService
    ) {
    }

    public init(map: Map) {
        this.map = map;

        this._routingService.route$.subscribe((route: RouteDefinition) => {
            this.route = route;
            this.redrawRoute();
        });
        this._routingService.active$.subscribe((active: RoutingMode) => {
            this.active = active;
            this.redrawRoute();
        });

        this._mapState.zoom$.subscribe(() => {
            this.redrawRoute();
        });

        this._appState.shortInfoComponentActive$.subscribe((shortInfoComponentActive: IShortInfoComponentContent) => {
            if (shortInfoComponentActive && shortInfoComponentActive.getClassType() == 'address') {
                this.activeAddress = <AddressAutosuggestEntry>shortInfoComponentActive;
            } else {
                this.activeAddress = null;
            }
            this.drawPlainAddressMarker();
        });
    }

    private static createIcon(iconSrc: string): MarkerOptions {
        const opts: MarkerOptions = {},
            icon = document.createElement('img');

        opts.element = icon;
        opts.draggable = false;
        opts.anchor = 'center';
        icon.classList.add('place-marker');
        icon.classList.add('pos-right');
        icon.style.width = '24px';
        icon.style.height = '24px';
        icon.src = iconSrc;

        return opts;
    }

    private addPlaceholderMarker(routable: IRoutable) {
        let walkingModes = [RoutingTransportation.MVG, RoutingTransportation.WALKING, RoutingTransportation.SIGHTSEEING];

        let iconSrc;
        if (walkingModes.indexOf(this._routingService.transportation$.getValue()) === -1) {
            iconSrc = 'https://app.muenchen.de/img/marker/category/fallback.svg';
        } else {
            iconSrc = 'images/routing_inbetween_walking.svg';
        }
        const opts = MapRoutingLayerService.createIcon(iconSrc);

        const marker = new Marker(opts);
        marker.setLngLat(routable.getPosition());
        marker.addTo(this.map);
        this.placeholderMarkers.push(marker);
    }

    private addInbetweenPlaceMarker(place: PlacesEntry) {
        let iconSrc = 'https://app.muenchen.de/img/marker/category/fallback.svg';
        if (place.iconUrlBase) {
            iconSrc = place.iconUrlBase + '.svg';
        }
        const opts = MapRoutingLayerService.createIcon(iconSrc);

        const marker = new Marker(opts);
        marker.setLngLat(place.getPosition());
        marker.addTo(this.map);
        this.inbetweenMarkers[place.id] = marker;
    }

    private drawMvgRoute() {
        let route: MVGRouteDefinition = <MVGRouteDefinition>this.route;
        let features = [];

        route.routeParts.forEach((routePart: MVGRouteDefinitionPart, i: number) => {
            let lnglats: any = routePart.polyline;

            if (i == 0 && routePart.transportationType == MVV_TYPE.WALKING) {
                let myPos = this.route.from.getPosition();
                lnglats.splice(0, 0, [myPos.lng, myPos.lat]);
            }

            let iconUrl: string;
            let lineOptions: any = {};
            switch (routePart.transportationType) {
                case MVV_TYPE.UBAHN:
                    lineOptions.color = '#0068b0'; // MVG-Standard
                    iconUrl = 'images/routing_ubahn.svg';
                    break;
                case MVV_TYPE.SBAHN:
                    lineOptions.color = '#408335'; // MVG-Standard
                    iconUrl = 'images/routing_sbahn.svg';
                    break;
                case MVV_TYPE.TRAM:
                    lineOptions.color = '#d82020'; // MVG-Standard
                    iconUrl = 'images/routing_tram.svg';
                    break;
                case MVV_TYPE.BAHN:
                    lineOptions.color = '#000000'; // MVG-Standard
                    iconUrl = 'images/routing_sbahn.svg';
                    break;
                case MVV_TYPE.BUS:
                    lineOptions.color = '#00586a'; // MVG-Standard
                    iconUrl = 'images/routing_bus.svg';
                    break;
                case MVV_TYPE.WALKING:
                    lineOptions = this.lineOptionsWalking;
                    iconUrl = 'images/routing_inbetween_walking.svg';
                    break;
                default:
                    lineOptions = this.lineOptionsWalking;
                    iconUrl = 'https://app.muenchen.de/img/marker/category/fallback.svg';
            }

            features.push({
                'type': 'Feature',
                'properties': lineOptions,
                'geometry': {
                    'type': 'LineString',
                    'coordinates': lnglats
                }
            });

            if (routePart.interchangeAfter.length > 0) {
                features.push({
                    'type': 'Feature',
                    'properties': this.lineOptionsWalking,
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': routePart.interchangeAfter
                    }
                });
            }

            if (i > 0) {
                const opts = MapRoutingLayerService.createIcon(iconUrl);
                const marker = new Marker(opts);
                marker.setLngLat(routePart.polyline[0]);
                marker.addTo(this.map);
                this.routingMarkers.push(marker);
            }
        });

        const geojson: any = {
            'id': 'current_route',
            'type': 'line',
            'source': {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': features
                },
            },
            'paint': {
                'line-width': 3,
                // Use a get expression (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-get)
                // to set the line-color to a feature property value.
                'line-color': ['get', 'color']
            }
        };

        this.map.addLayer(geojson);
        this.currentRoutePolyline = 'current_route';
    }

    private drawRegularRoute() {
        let lnglats: any[] = [this.route.overview_polyline];

        let properties;
        if (this.route.transportationType == RoutingTransportation.WALKING) {
            properties = this.lineOptionsWalking;
        } else if (this.route.transportationType == RoutingTransportation.SIGHTSEEING) {
            properties = this.lineOptionsWalking;
        } else if (this.route.transportationType == RoutingTransportation.BIKE) {
            properties = {color: '#086a36'}; // MVG-Standard
        } else {
            properties = {color: '#005a9f'};
        }

        let features = [];
        lnglats.forEach(polyline => {
            features.push({
                'type': 'Feature',
                'properties': properties,
                'geometry': {
                    'type': 'LineString',
                    'coordinates': polyline
                }
            });
        });

        const geojson: any = {
            'id': 'current_route',
            'type': 'line',
            'source': {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': features
                },
            },
            'paint': {
                'line-width': 3,
                // Use a get expression (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-get)
                // to set the line-color to a feature property value.
                'line-color': ['get', 'color']
            }
        };

        this.map.addLayer(geojson);
        this.currentRoutePolyline = 'current_route';

        // let drawPlaces: PlacesEntry[] = this.route.inbetweenPlaces.sort(RichMarkerPos.sortPlacesToAvoidLabelCollissions);
        this.route.inbetweenPlaces.forEach((place: PlacesEntry) => {
            this.addInbetweenPlaceMarker(place);
        });
    }

    private redrawRoute() {
        if (this.currentRoutePolyline) {
            this.map.removeLayer(this.currentRoutePolyline);
            this.map.removeSource(this.currentRoutePolyline);
        }
        Object.keys(this.inbetweenMarkers).forEach((id: string) => {
            this.inbetweenMarkers[id].remove();
        });
        this.routingMarkers.forEach((marker: Marker) => {
            marker.remove();
        });
        this.placeholderMarkers.forEach((marker: Marker) => {
            marker.remove();
        });
        this.placeholderMarkers = [];
        this.currentRoutePolyline = null;
        this.inbetweenMarkers = {};
        this.routingMarkers = [];

        if (this.route && this.route.from && this.route.from.needsRoutingPlaceholderIcon()) {
            this.addPlaceholderMarker(this.route.from);
        }
        if (this.route && this.route.to && this.route.to.needsRoutingPlaceholderIcon()) {
            this.addPlaceholderMarker(this.route.to);
        }

        if (this.route && this.active != RoutingMode.NONE) {
            if (this.route.transportationType == RoutingTransportation.MVG) {
                this.drawMvgRoute();
            } else {
                this.drawRegularRoute();
            }
        }
    }

    private drawPlainAddressMarker() {
        if (this.activeAddressMarker) {
            this.activeAddressMarker.remove();
        }

        if (!this.activeAddress) {
            return;
        }

        const opts: MarkerOptions = {},
            icon = document.createElement('img');

        opts.element = icon;
        opts.draggable = false;
        opts.anchor = 'center';
        icon.classList.add('place-marker');
        icon.classList.add('pos-right');
        icon.style.width = '50px';
        icon.style.height = '72px';
        icon.src = 'https://app.muenchen.de/img/marker/category/fallback-active.svg';

        this.activeAddressMarker = new Marker(opts);
        this.activeAddressMarker.setLngLat(this.activeAddress.position);
        this.activeAddressMarker.addTo(this.map);
    }
}
