<div class="mvg-entry-list">
    <div class="departure-head head">
        <!--<img src="images/mvv_logo.png" alt="MVV" class="mvv-logo" *ngIf="!minimalistic">-->
        <h2 *ngIf="!minimalistic">{{ station?.getShortInfoTitle() }}</h2>
        <ul class="mvg-transportation-list type-list">
            <ng-container *ngFor="let category of sortedPublicCats">
                <li *ngIf="category.id == 'mv.public.ubahn'" [class.active]="transportFilter == 'ubahn'">
                    <img src="images/pt_u.png" alt="U-Bahn" class="transport-icon"
                         (click)="toggleTransport('ubahn')">
                </li>
                <li *ngIf="category.id == 'mv.public.bus'" [class.active]="transportFilter == 'bus'">
                    <img src="images/pt_b.png" alt="Bus" class="transport-icon"
                         (click)="toggleTransport('bus')">
                </li>
                <li *ngIf="category.id == 'mv.public.tram'" [class.active]="transportFilter == 'tram'">
                    <img src="images/pt_t.png" alt="Tram" class="transport-icon"
                         (click)="toggleTransport('tram')">
                </li>
                <li *ngIf="category.id == 'mv.public.sbahn'" [class.active]="transportFilter == 'sbahn'">
                    <img src="images/pt_s.png" alt="S-Bahn" class="transport-icon"
                         (click)="toggleTransport('sbahn')">
                </li>
            </ng-container>
        </ul>
        <div *ngIf="mvgDepartures === null">{{ messages.departures_loading }}</div>
        <div *ngIf="mvgDepartures !== null && !minimalistic">{{ messages.departures_next }}</div>
    </div>
    <ol class="mvg-departure-list entry-list">
        <li *ngFor="let departure of filteredDepartures">
            <div class="transport">
                <img *ngIf="departure.explicitIcon" [src]="departure.explicitIcon" alt="I">
                <span *ngIf="!departure.explicitIcon" class="colored-number" [style.background]="departure.lineBackgroundColor">
                    {{ departure.name }}
                </span>
            </div>
            <div class="destination">
                {{ departure.destination }}
            </div>
            <div class="time">
                {{ departure.time | relativetime }}
            </div>
        </li>
    </ol>
</div>
