<div class="head">
    <img src="images/mobility_station-01.svg" alt="Mobilitätsstation" class="mobility-logo">
    <h2>{{ station?.getName() }}</h2>
</div>
<ul class="tab-switcher">
    <li *ngIf="station?.hasPublicTransport()" [class.active]="activeTab == 'publicTransportation'"
        (click)="setActiveTab('publicTransportation')">
        <img src="images/mobility_Icon-Nahverkehr.svg" alt="ÖPNV" *ngIf="activeTab != 'publicTransportation'">
        <img src="images/mobility_Icon-Nahverkehr_active.svg" alt="ÖPNV" *ngIf="activeTab == 'publicTransportation'">
        <div>{{ messages.mobility_tab_public }}</div>
    </li>
    <li *ngIf="station?.hasAnyBike()" [class.active]="activeTab == 'bikes'"
        (click)="setActiveTab('bikes')">
        <img src="images/mobility_Icon-Rad.svg" alt="Rad" *ngIf="activeTab != 'bikes'">
        <img src="images/mobility_Icon-Rad_active.svg" alt="Rad" *ngIf="activeTab == 'bikes'">
        <div>{{ messages.mobility_tab_bike }}</div>
    </li>
    <li *ngIf="station?.hasChargingPoints()" [class.active]="activeTab == 'chargingPoint'"
        (click)="setActiveTab('chargingPoint')">
        <img src="images/mobility_laden.svg" alt="Charging" *ngIf="activeTab != 'chargingPoint'">
        <img src="images/mobility_laden_active.svg" alt="Charging" *ngIf="activeTab == 'chargingPoint'">
        <div>{{ messages.mobility_tab_charging }}</div>
    </li>
    <li *ngIf="station?.hasCars()" [class.active]="activeTab == 'cars'"
        (click)="setActiveTab('cars')">
        <img src="images/mobility_carsharing.svg" alt="Carsharing" *ngIf="activeTab != 'cars'">
        <img src="images/mobility_carsharing_active.svg" alt="Carsharing" *ngIf="activeTab == 'cars'">
        <div>{{ messages.mobility_tab_car }}</div>
    </li>
    <li *ngIf="station?.hasDistrictbox()" [class.active]="activeTab == 'districtbox'"
        (click)="setActiveTab('districtbox')">
        <img src="images/mobility_districtbox.svg" alt="Quartiersbox" *ngIf="activeTab != 'districtbox'">
        <img src="images/mobility_districtbox_active.svg" alt="Quartiersbox" *ngIf="activeTab == 'districtbox'">
        <div>{{ messages.mobility_tab_districtbox }}</div>
    </li>
</ul>

<div class="tab-holder">
    <section class="tab tab-public" [class.active]="activeTab == 'publicTransportation'"
             *ngIf="station?.hasPublicTransport()">
        <short-info-mvv-departures [station]="station?.getPublicTransportStation()"
                                   [minimalistic]="true"></short-info-mvv-departures>
    </section>
    <section class="tab tab-bikes mvg-entry-list" [class.active]="activeTab == 'bikes'"
        *ngIf="station?.hasAnyBike()">
        <short-info-bike-station [station]="station"></short-info-bike-station>
    </section>
    <section class="tab tab-charging mvg-entry-list" [class.active]="activeTab == 'chargingPoint'">
        <short-info-chargers [stations]="station?.getChargingStations()"></short-info-chargers>
    </section>
    <section class="tab tab-cars mvg-entry-list" [class.active]="activeTab == 'cars'">
        <div class="head">
            <ul class="type-list">
                <li><img src="images/carsharing_statt_auto.png" alt="Stattauto"></li>
            </ul>
        </div>
        <ul class="bike-list entry-list">
            <li *ngFor="let car of station?.getStaticCars()">
                <div class="icon">
                    <img src="images/carsharing_statt_auto.png" alt="Station">
                </div>
                <div class="title-availability">
                    <div class="title">{{ car.getVendorName() }}</div>
                    <div class="availability">{{ car.getName() }}</div>
                </div>
                <!--
                <div class="order">
                    <a href="">{{ messages.mobility_car_order }}</a>
                </div>
                -->
            </li>
            <li *ngFor="let car of station?.getVolatileCars()">
                <div class="icon">
                    <img src="images/carsharing_car2go.png" alt="Car">
                </div>
                <div class="title-availability">
                    <div class="title">{{ car.getName() }}</div>
                </div>
                <!--
                <div class="order">
                    <a href="">{{ messages.mobility_car_order }}</a>
                </div>
                -->
            </li>
        </ul>
    </section>
    <section class="tab tab-districtbox" [class.active]="activeTab == 'districtbox'">
        <div class="head">
            Quartiersbox
        </div>
    </section>
</div>