import {Injectable} from "@angular/core";
import {EchtzeitproxyService} from "../apis/echtzeitproxy";
import {PlacesEntry} from "../classes/PlacesEntry";
import {IShortInfoComponentContent} from "../classes/IShortInfoComponentContent";
import {MarkerCollectionStore} from "../classes/MarkerCollectionStore";
import { BuildingWithPolygon, RoutingMode } from "../interfaces";
import { AnySourceData, Map, Marker } from "mapbox-gl";
import {EpBoundingboxLoaderService} from "../services/ep-boundingbox-loader.service";
import {RoutingService} from "../services/routing.service";
import {AppStateService} from "../services/app-state.service";
import {MapStateService} from "../services/map-state.service";
import {MuenchenBuildingsService} from "../apis/buildings.service";
import {PlacesDetails} from "../classes/PlacesDetails";

const CUSTOMERS_ABOVE_ZOOM = 19;
const NO_PLACES_BELOW_ZOOM = 13;

@Injectable()
export class MapPlacesLayerService {
    private map: Map;
    //private Z_INDEX_OFFSET: 0;
    private categorySelected: string;
    private markers: { [id: string]: Marker } = {};
    private markerCollection: MarkerCollectionStore<PlacesEntry> = null;
    private zoom: number = null;
    private language: string = 'de';
    private currentPlaces: PlacesEntry[] = [];
    private hiddenPlaceIds: string[] = [];

    constructor(private _mapState: MapStateService,
                private _places: EchtzeitproxyService,
                private _epBBLoader: EpBoundingboxLoaderService,
                private _buildings: MuenchenBuildingsService,
                private _appState: AppStateService,
                private _routingService: RoutingService) {
    }

    public init(map: Map) {
        this.map = map;

        this._appState.language$.subscribe((language) => {
            this.language = language.language;
        });

        console.log("init places");
        this._buildings.getBuildings().subscribe((buildings) => {
            const geojson: AnySourceData = {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': []
                }
            };
            buildings.forEach((building: BuildingWithPolygon) => {
                geojson.data['features'].push({
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Polygon',
                        'coordinates': building.polygon
                    },
                    'properties': {
                        'id': building.placeId,
                        'title': building.name,
                    }
                });
                this.hiddenPlaceIds.push(building.placeId);
                this.delPlaceMarkerById(building.placeId);
            });
            this.map.addLayer({
                'id': 'building-polygons',
                'type': 'fill',
                'source': geojson,
                'layout': {},
                'paint': {
                    'fill-color': '#ff0000',
                    'fill-opacity': 0
                }
            });
            this.map.on('mousemove', 'building-polygons', (e) => {
                if (e.features.length > 0) {
                    map.getCanvas().style.cursor = 'pointer'
                } else {
                    map.getCanvas().style.cursor = ''
                }
            });
            map.on('mouseleave', 'building-polygons', () => {
                map.getCanvas().style.cursor = ''
            });
            map.on('click', 'building-polygons', (e) => {
                if (e.features.length > 0) {
                    this._places.loadDetails(e.features[0].properties.id, this.language).subscribe((place: PlacesDetails) => {
                        this._appState.openPlaceInShortInfo(place.toNonDetailedEntry());
                    });
                }
            });
            console.log("hidden", this.hiddenPlaceIds);
        });

        this.markerCollection = new MarkerCollectionStore<PlacesEntry>(
            this.addPlaceAsInactiveRichMarker.bind(this),
            this.delPlaceMarker.bind(this),
            this.addPlaceAsActiveRichMarker.bind(this),
            this.delPlaceMarker.bind(this),
        );

        this._appState.shortInfoComponentActive$.subscribe((shortInfoComponentActive: IShortInfoComponentContent) => {
            if (shortInfoComponentActive && shortInfoComponentActive.getClassType() == 'place') {
                this.markerCollection.setActiveElement(<PlacesEntry> shortInfoComponentActive);
            } else {
                this.markerCollection.setActiveElement(null);
            }
        });
        this._appState.categorySelected$.subscribe((categorySelected: string) => {
            this.categorySelected = categorySelected;
            this.setCategoryBBListener();
        });
        this._epBBLoader.setListenerCategories('places', [this.categorySelected]).subscribe((data) => {
            this.receivedPlaces(data.places);
        });
        this._routingService.active$.subscribe(this.routingChanged.bind(this));
        this._routingService.from$.subscribe(this.routingChanged.bind(this));
        this._routingService.to$.subscribe(this.routingChanged.bind(this));

        this.initPlaceFlushListener();
    }

    private setCategoryBBListener() {
        if (this.zoom > CUSTOMERS_ABOVE_ZOOM) {
            this._epBBLoader.setListenerCategories('places', [this.categorySelected]);
        } else if (this.zoom <= NO_PLACES_BELOW_ZOOM) {
            this._epBBLoader.setListenerCategories('places', []);
        } else {
            this._epBBLoader.setListenerCategories('places', [this.categorySelected]);
        }
    }

    routingChanged() {
        this.showCurrentPlaces();
    }

    private delPlaceMarkerById(markerId: string) {
        // Es kann auch sein, dass Marker in addPlaceAsInactiveRichMarker dann doch nicht angezeigt werden, wegen Kollission
        // In diesem Fall gibt es hier dann keinen Eintrag, obwohl es noch in der MarkerCollection registriert ist
        if (this.markers[markerId]) {
            this.markers[markerId].remove();
            delete(this.markers[markerId]);
        }
    }

    private delPlaceMarker(markerData: PlacesEntry) {
        this.delPlaceMarkerById(markerData.id);
    }

    private createMarker(iconUrl: string, classes: string[], markerData: PlacesEntry, onClick: () => any) {
        const el = document.createElement('div');
        classes.forEach(cla => {
            el.classList.add(cla);
        });
        const img = document.createElement('img');
        img.src = iconUrl;
        img.alt = '◉';
        el.appendChild(img);
        const title = document.createElement('div');
        title.classList.add('title');
        title.classList.add('movable');
        title.innerText = markerData.getShortInfoTitle();
        el.appendChild(title);

        el.addEventListener('click', ev => {
            ev.stopPropagation();
            onClick();
        });

        // make a marker for each feature and add to the map
        const marker = new Marker(el);
        marker.setLngLat(markerData.position);
        marker.addTo(this.map);

        return marker;
    }

    private addPlaceAsInactiveRichMarker(markerData: PlacesEntry) {
        let iconUrl: string = 'https://app.muenchen.de/img/marker/category/fallback.svg';
        if (markerData.iconUrlBase) {
            iconUrl = markerData.iconUrlBase + '.svg';
        }
        let classes = ['place-marker', 'pos-right'];
        if (this.hiddenPlaceIds.indexOf(markerData.id) !== -1) {
            classes.push('building')
        }

        this.markers[markerData.id] = this.createMarker(iconUrl, classes, markerData, () => {
            this._appState.openPlaceInShortInfo(markerData);
        });
    }

    private addPlaceAsActiveRichMarker(markerData: PlacesEntry) {
        let iconUrl: string = 'https://app.muenchen.de/img/marker/category/fallback-active.svg';
        if (markerData.iconUrlBase) {
            iconUrl = markerData.iconUrlBase + '-active.svg';
        }

        this.markers[markerData.id] = this.createMarker(iconUrl, ['place-marker-active', 'pos-right'], markerData, () => {
            this._appState.openPlaceInShortInfo(markerData);
        });
    }

    private insertActivePlacesIfMissing(places: PlacesEntry[]) {
        let ids: string[] = [];
        places.forEach((place: PlacesEntry) => {
            ids.push(place.getId());
        });

        let active = this._appState.shortInfoComponentActive$.getValue();
        if (active && active.getClassType() == 'place' && ids.indexOf(active.getId()) === -1) {
            places.unshift(<PlacesEntry>active);
            ids.push(active.getId());
        }

        return places;
    }

    private initPlaceFlushListener() {
        this._mapState.zoom$.subscribe((zoom: number) => {
            if (!zoom || zoom === this.zoom) {
                return;
            }
            this.zoom = zoom;
            this.setCategoryBBListener();
            /*
            Vor/Nachteil:
            Hier leeren forciert eine neue Kollissionsvermeidung und sorgt beim Herauszoomen daher für weniger Überlappungen
            Dafür flackert es beim Zoomen etwas mehr.
            this.markerCollection.setNewElementCollection(
                this.insertActivePlacesIfMissing([])
            );

            let map_node: Element = document.getElementById('map_holder');
            if (zoom > MuenchenBuildingsService.HALF_TRANSPARENT_ZOOM_LEVEL) {
                map_node.classList.add('buildings-shown');
                map_node.classList.remove('buildings-transparent');
                map_node.classList.remove('buildings-hidden');
            } else if (zoom === MuenchenBuildingsService.HALF_TRANSPARENT_ZOOM_LEVEL) {
                map_node.classList.remove('buildings-shown');
                map_node.classList.add('buildings-transparent');
                map_node.classList.remove('buildings-hidden');
            } else {
                map_node.classList.remove('buildings-shown');
                map_node.classList.remove('buildings-transparent');
                map_node.classList.add('buildings-hidden');
            }
            */
        });
    }

    private showCurrentPlaces() {
        if (this._routingService.active$.getValue() !== RoutingMode.NONE) {
            let toBeShown: PlacesEntry[] = [];
            let from = this._routingService.from$.getValue();
            let to = this._routingService.to$.getValue();
            if (from && from.getClassType() == 'place') {
                toBeShown.push(<PlacesEntry>from);
            }
            if (to && to.getClassType() == 'place') {
                toBeShown.push(<PlacesEntry>to);
            }
            this.markerCollection.setNewElementCollection(toBeShown);
        } else {
            this.markerCollection.setNewElementCollection(this.currentPlaces);
        }
    }

    private receivedPlaces(places: PlacesEntry[]) {
        // places = places.sort(RichMarkerPos.sortPlacesToAvoidLabelCollissions);
        places = places.filter((place: PlacesEntry) => {
            if (Math.round(this.zoom) <= 14) {
                // https://trello.com/c/fTX5XsKN/46-in-dieser-zoomstufe-soll-kindermuseum-und-g%C3%A4rtnerplatz-raus
                return (['bb.119179', 'bb.139972'].indexOf(place.id) === -1)
            } else {
                return true;
            }
        });
        this.currentPlaces = this.insertActivePlacesIfMissing(places);
        this._appState.placesReloaded(this.currentPlaces);
        this.showCurrentPlaces();
    }
}
