import {Messages} from "./messages";

export const MESSAGES_EN: Messages =
    {
        language: "en",
        search: {
            none: "Nothing found",
            placeholder: "What are you looking for?",
        },
        sidebar: {
            title_tiles: "Map mode",
            title_options: "Display options",
            layer_mvv: "Public transport",
            layer_rad: "MVG bike-sharing",
            layer_escooter: "E-Scooter",
            layer_tour: "Proposed tour",
            layer_charging_stations: "E-charging stations",
            tile_basis: "Map view",
            tile_hausnummern: "House numbers",
            tile_luftbild: "Aerial view",
            muc_tourism: "Tourism portal",
            muc_portal: "muenchen.de",
            accessible: "Barrier-free",
            stele_accessible: "Orientation points",
            tourism_accessible: "Tourism",
            impressum: "Legal",
            privacy: "Privacy",
            accessibility_hint_text: "This site is also available in easy language and in audio form.",
            accessibility_hint_link: "For barrier-free view",
        },
        routing: {
            title: "Calculate route",
            from: "From",
            from_placeholder: "Choose starting point",
            to: "To",
            to_placeholder: "Choose destination",
            public_title: "Public transport",
            departure: "Departure",
            calculating_route: "Calculate the route ...",
            autosuggest: "Suggested",
            show_route: "Show route",
        },
        shortinfo: {
            time_rel_hours: "h",
            time_rel_minutes: "m",
            opening_hours: "Open today",
            bike_free: 'MVG bike',
            bike_station: 'MVG bike station',
            etrike_station: 'MVG eTrike station',
            pedelec_station: 'MVG eBike station',
            bike_no: 'MVG Bike no.',
            bikes_available_pre: 'Still',
            bikes_available: 'MVG bikes available',
            bike_available: 'MVG bike available',
            pedelec_no: 'MVG eBike no.',
            pedelecs_available_pre: 'Still',
            pedelecs_available: 'MVG eBikes available',
            pedelec_available: 'MVG eBike available',
            etrike_no: 'MVG eTrike No.',
            etrikes_available_pre: 'Still',
            etrikes_available: 'MVG eTrikes available',
            etrike_available: 'MVG eTrike available',
            departures_loading: "Loading next departures",
            departures_next: "Next departures",
            departure_in: "In",
            stattauto_loading: "Loading stattautos...",
            mobility_tab_public: "Public",
            mobility_tab_bike: "MVG Bike",
            mobility_tab_charging: "Charging",
            mobility_tab_car: "Car sharing",
            mobility_tab_districtbox: "District box",
            mobility_bike_available_1: "1 bike available",
            mobility_bike_available_x: "bikes available",
            mobility_bike_order: "Order bike",
            mobility_charging_order: "Charge now",
            mobility_car_order: "Order car",
            charging_not_available: "Not available",
        },
        details: {
            back: 'Back',
            loading: 'Loading',
            open_website: 'Website',
            open_mvg: 'Public Transport',
            open_telephone: 'Phone',
            open_photoupload: 'Photo-Upload'
        }
    };
