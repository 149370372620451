import {Component} from '@angular/core';
import {Messages, SidebarMessages} from "../messages/messages";
import {AppStateService} from "../services/app-state.service";
import {RoutingService} from "../services/routing.service";
import {RoutingTransportation} from "../interfaces";

@Component({
    selector: 'routing-touristic-info',
    templateUrl: 'routing-touristic-info.component.html',
    styles: [],
})
export class RoutingTouristicComponent {
    public show: boolean;
    public messages: SidebarMessages = null;

    public constructor(private _appState: AppStateService, private _routingState: RoutingService) {
        this.show = false;

        this._appState.language$.subscribe((messages: Messages) => {
            this.messages = messages.sidebar;
        });
        this._routingState.transportation$.subscribe((transport: RoutingTransportation) => {
            if (transport == RoutingTransportation.SIGHTSEEING) {
                this.show = !this.getShown();
            }
        });
    }

    public close() {
        this.setShown();
        this.show = false;
    }

    private getShown(): boolean {
        if (!localStorage) {
            return false;
        }
        let shown = localStorage.getItem('routing_touristic_shown');
        if (shown === null) {
            return false;
        }
        return (shown === '1');
    }

    private setShown() {
        if (!localStorage) {
            return;
        }
        localStorage.setItem('routing_touristic_shown', '1');
    }
}
