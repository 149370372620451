import {Injectable} from "@angular/core";
import {IRoutable} from "../classes/IRoutable";
import {
    ALL_ROUTING_TRANSPORTATIONS,
    MVGRouteDefinition,
    RouteDefinition,
    RouteDefinitionWithAlternatives,
    RoutingMode,
    RoutingTransportation
} from "../interfaces";
import {MVVRoutingService} from "../apis/mvv-routing.service";
import {MvgRoutingService} from "../apis/mvg-routing.service";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {MapStateService} from "./map-state.service";
import {SettingsService} from "./settings.service";
import {MyLocation} from "../classes/MyLocation";
import {AppStateService} from "./app-state.service";
import {IShortInfoComponentContent} from "../classes/IShortInfoComponentContent";
import {PlacesEntry} from "../classes/PlacesEntry";
import { LngLat, LngLatBounds } from 'mapbox-gl';
import { GeoFunctions } from '../classes/GeoFunctions';

@Injectable()
export class RoutingService {
    constructor(
        private _mvvRouting: MVVRoutingService,
        private _mapboxRouting: MvgRoutingService,
        private _settings: SettingsService,
        private _appState: AppStateService,
        private _mapState: MapStateService) {

        this._appState.closePrettyMuchEverythingEvent$.subscribe(this.onClosePrettyMuchEverything.bind(this));
        this._appState.shortInfoComponentActive$.subscribe(this.onShortInfoComponent.bind(this));
        this._mapState.myLocationLngLat$.subscribe(this.onMyLocationChanged.bind(this));
    }

    public active$: BehaviorSubject<RoutingMode> = new BehaviorSubject(RoutingMode.NONE);
    public from$: BehaviorSubject<IRoutable> = new BehaviorSubject<IRoutable>(null);
    public to$: BehaviorSubject<IRoutable> = new BehaviorSubject<IRoutable>(null);

    public route$: BehaviorSubject<RouteDefinition> = new BehaviorSubject<RouteDefinition>(null);

    public allTransportationRoutes$: BehaviorSubject<{ [transport: number]: RouteDefinition }> =
        new BehaviorSubject<{ [p: number]: RouteDefinition }>(null);

    public allMvgRoutes$: BehaviorSubject<MVGRouteDefinition[]> = new BehaviorSubject<MVGRouteDefinition[]>(null);

    public transportation$: BehaviorSubject<RoutingTransportation> =
        new BehaviorSubject<RoutingTransportation>(RoutingTransportation.WALKING);

    public openPanel() {
        this.active$.next(RoutingMode.ACTIVE);
        if (this.route$.getValue()) {
            this.showRoute(this.route$.getValue());
        }
    }

    public closePanel() {
        this.active$.next(RoutingMode.NONE);
    }

    public setFullscreen() {
        this.active$.next(RoutingMode.FULLSCREEN);
    }

    public leaveFullscreen() {
        this.active$.next(RoutingMode.ACTIVE);
    }

    public setFrom(from: IRoutable, reload = false) {
        this.from$.next(from);
        if (reload) {
            const showRoute = (this.active$.getValue() !== RoutingMode.NONE);
            this.loadRoute(showRoute);
        }
    }

    public setTo(to: IRoutable, reload = false) {
        this.to$.next(to);
        if (reload) {
            const showRoute = (this.active$.getValue() !== RoutingMode.NONE);
            this.loadRoute(showRoute);
        }
    }

    public setFromTo(from: IRoutable, to: IRoutable, reload = false) {
        this.from$.next(from);
        this.to$.next(to);
        if (reload) {
            this.loadRoute();
        }
    }

    public switchFromTo(reload = false) {
        let from = this.from$.getValue();
        let to = this.to$.getValue();
        this.from$.next(to);
        this.to$.next(from);
        if (reload) {
            this.loadRoute();
        }
    }

    public setTransportation(transport: RoutingTransportation, load = false, setSettings = false) {
        this.transportation$.next(transport);
        if (load) {
            this.loadRoute();
        }
        if (setSettings) {
            this._settings.setRoutingTransportation(transport);
        }
    }

    public resetRoutes() {
        this.route$.next(null);
        this.allTransportationRoutes$.next(null);
        this.allMvgRoutes$.next(null);
    }

    public deactivate() {
        this.route$.next(null);
        this.allTransportationRoutes$.next(null);
        this.setFrom(null);
        this.setTo(null);
        this.closePanel();
    }

    public switchMvgRoute(route: RouteDefinition) {
        let newAll = Object.assign({}, this.allTransportationRoutes$.getValue());
        newAll[RoutingTransportation.MVG] = route;
        this.route$.next(route);
        this.allTransportationRoutes$.next(newAll);
    }

    public setAndLoadWithoutDisplaying(routableTo: IRoutable) {
        this.setFrom(this.getMyLocation());
        this.setTo(routableTo);
        this.loadRoute(false);
    }

    public activate(routableTo?: IRoutable) {
        this.openPanel();

        if (routableTo) {
            this.setAndLoadWithoutDisplaying(routableTo);
        }
    }

    private getMyLocation(): MyLocation {
        let location: LngLat;
        let isFallback: boolean = false;

        if (MyLocation.currMyLocationObject) {
            location = MyLocation.currMyLocationObject.getPosition();
        } else {
            location = MyLocation.DEFAULT_LOCATION;
            isFallback = true;
        }
        return new MyLocation(location, isFallback);
    }

    public showRoute(route: RouteDefinition) {
        if (this.active$.getValue() === RoutingMode.NONE) {
            this.active$.next(RoutingMode.ACTIVE);
        }

        let bounds: LngLatBounds = GeoFunctions.polylineToBounds(route.overview_polyline);
        this._mapState.triggerGotoBounds(bounds);
    }

    public allRoutesLoaded(allRoutes: { [transport: number]: RouteDefinition }, mvgRoutes: MVGRouteDefinition[]) {
        this.allMvgRoutes$.next(mvgRoutes);
        this.allTransportationRoutes$.next(allRoutes);
    }

    public loadRouteByTransport(routeFrom: IRoutable, routeTo: IRoutable, transportation: RoutingTransportation): Promise<RouteDefinitionWithAlternatives> {
        if (transportation == RoutingTransportation.MVG) {
            return new Promise<RouteDefinitionWithAlternatives>((resolve/*, reject*/) => {
                this._mvvRouting.getRoutes(routeFrom, routeTo)
                    .subscribe((route: RouteDefinitionWithAlternatives) => {
                        resolve(route);
                    })
            });
        } else if (transportation == RoutingTransportation.SIGHTSEEING) {
            return new Promise<RouteDefinitionWithAlternatives>((resolve/*, reject*/) => {
                this._mapboxRouting.getSightseeingRoute(routeFrom, routeTo)
                    .then((route: RouteDefinition) => {
                        resolve({route: route, allRoutes: []});
                    })
            });
        } else {
            return new Promise<RouteDefinitionWithAlternatives>((resolve/*, reject*/) => {
                this._mapboxRouting.getRoute(routeFrom, routeTo, transportation)
                    .subscribe((route: RouteDefinition) => {
                        resolve({route: route, allRoutes: []});
                    })
            });
        }
    }

    public loadAllRoutes(routeFrom: IRoutable, routeTo: IRoutable) {
        let promises: Promise<RouteDefinitionWithAlternatives>[] = [],
            allRoutes: { [transport: number]: RouteDefinition } = {},
            mvgRoutes: MVGRouteDefinition[] = [];

        ALL_ROUTING_TRANSPORTATIONS.forEach((transport: RoutingTransportation) => {
            let promise = this.loadRouteByTransport(routeFrom, routeTo, transport);
            promises.push(promise);
            promise.then((data: RouteDefinitionWithAlternatives) => {
                if (!data) {
                    allRoutes[transport] = null;
                    return;
                }
                allRoutes[transport] = data.route;
                if (transport === RoutingTransportation.MVG) {
                    mvgRoutes = <MVGRouteDefinition[]> data.allRoutes;
                }
            });
        });

        return Promise.all(promises).then(() => {
            console.log("Loaded all routes: ", {
                allRoutes: allRoutes,
                mvgRoutes: mvgRoutes,
            });
            return {
                allRoutes: allRoutes,
                mvgRoutes: mvgRoutes,
            };
        });
    }

    private loadRoute(showRoute = true) {
        let from = this.from$.getValue();
        let to = this.to$.getValue();

        if (!from || !to || from.getId() == to.getId()) {
            this.resetRoutes();
            return;
        }

        let allRoutes = this.allTransportationRoutes$.getValue();
        if (allRoutes && allRoutes[this.transportation$.getValue()]) {
            let curr: RouteDefinition = allRoutes[this.transportation$.getValue()];

            // Die Position kann sich ändern, insb. bei MyLocation, deswegen auch eine Entfernungsüberprüfung
            let fromSame = (curr.from.getId() == from.getId() && GeoFunctions.getDistance(curr.from.getPosition(), from.getPosition()) < 100);
            let toSame = (curr.to.getId() == to.getId() && GeoFunctions.getDistance(curr.to.getPosition(), to.getPosition()) < 100);
            if (fromSame && toSame) {
                this.route$.next(curr);
                if (showRoute) {
                    this.showRoute(curr);
                }
                return;
            }
        }

        this.resetRoutes();

        this.loadAllRoutes(from, to).then((data) => {
            this.allRoutesLoaded(data['allRoutes'], data['mvgRoutes']);
            if (data['allRoutes'][this.transportation$.getValue()]) {
                this.route$.next(data['allRoutes'][this.transportation$.getValue()]);
                if (showRoute) {
                    this.showRoute(data['allRoutes'][this.transportation$.getValue()]);
                }
            }
        });
    }

    private onClosePrettyMuchEverything() {
        this.deactivate();
    }

    private onShortInfoComponent(component: IShortInfoComponentContent) {
        if (component && component.getClassType() == 'place') {
            let place = <PlacesEntry>component;
            this.setAndLoadWithoutDisplaying(place);
        }
        this.active$.next(RoutingMode.NONE); // Routing-Linie wird erst im Routing-Panel angezeigt
    }

    private onMyLocationChanged(location: LngLat) {
        if (!location) {
            return;
        }
        // Da die Entfernungsanzeige in der Karte auf Routing-Informationen basiert,
        // sollte nicht auf this.active$.getValue() überprüft werden, da das noch NONE sein kann
        if (this.from$.getValue() === null || this.to$.getValue() === null) {
            console.log("routing: not active");
            return;
        }
        if (!this.from$.getValue() || this.from$.getValue().getId() !== 'my_location') {
            return;
        }
        let distance = GeoFunctions.getDistance(this.from$.getValue().getPosition(), location);
        if (distance > 100) {
            console.log("Moved for mor than 100m - recalculating the route");
            this.setFrom(new MyLocation(location, false), true);
        }
    }
}
