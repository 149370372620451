<div class="routing-transportation" *ngIf="routesLoaded">
    <ng-container *ngFor="let routing of currentlyAvailableRoutings()">
        <div *ngIf="formattedRouteTimes[routing.id]">
            <label [class.active]="transportation == routing.id">
                <input type="radio" name="routing-transportation" [value]="routing.id"
                       [checked]="transportation == routing.id"
                       (change)="setTransportation(routing.id)" class="sr-only">
                <span class="transportation-radio">
                <img [src]="routing.img_active" [alt]="routing.alt" class="transportation-icon"
                     *ngIf="transportation == routing.id">
                <img [src]="routing.img_inactive" [alt]="routing.alt" class="transportation-icon"
                     *ngIf="transportation != routing.id">
                <span class="time" [innerHtml]="formattedRouteTimes[routing.id]"></span>
            </span>
            </label>
        </div>
    </ng-container>
</div>