import {Component, AfterViewInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ElasticsearchPlacesService} from "../apis/elasticsearch-places.service";
import {TypeaheadMatch} from "../ng2-bootstrap/typeahead/typeahead-match.class";
import {LastSearchesService} from "../services/last-searches.service";
import {AddressAutosuggestService} from "../apis/address-autosuggest.service";
import {MapStateService} from "../services/map-state.service";
import {AddressAutosuggestEntry} from "../classes/AddressAutosuggestEntry";
import {Messages, SearchMessages} from "../messages/messages";
import {DEFAULT_CATEGORY, RoutingMode} from "../interfaces";
import {RoutingService} from "../services/routing.service";
import {AppStateService} from "../services/app-state.service";
import { LngLat } from 'mapbox-gl';


@Component({
    selector: 'search-bar',
    templateUrl: './search.component.html',
})
export class SearchComponent implements AfterViewInit {
    public dataSource: Observable<any>;
    public asyncSelected: string = '';
    public typeaheadLoading: boolean = false;
    public typeaheadNoResults: boolean = false;
    public typeaheadPlacement: string = 'bottom-left';

    public mapCenter: LngLat;

    public messages: SearchMessages;
    public language: string;
    public hidden: boolean = false;

    private selectedId: string = null;

    public constructor(private _branchenbuch: ElasticsearchPlacesService,
                       private _lastSearches: LastSearchesService,
                       private _globalMapActions: MapStateService,
                       private _appState: AppStateService,
                       private _addressAutosuggest: AddressAutosuggestService,
                       private _routingService: RoutingService) {

        this.dataSource = new Observable<any>((observer: any) => {
            const searchFor = this.asyncSelected;
            let promise1: Promise<any> = this._branchenbuch.searchPlacesAndCategoriesByQuery(searchFor, this.language, this.mapCenter),
                promise2: Promise<any> = this._addressAutosuggest.getAutoSuggests(searchFor);

            Promise.all([promise1, promise2]).then((results: [any, any]) => {
                let newresults = this.calculateResults(this.asyncSelected, results[0], results[1]);
                if (searchFor === this.asyncSelected) {
                    observer.next(newresults);
                } else {
                    console.log("Received search results are obsolete by now");
                }
            });
        });

        this._appState.language$.subscribe((messages: Messages) => {
            this.messages = messages.search;
            this.language = messages.language;
        });

        this._appState.shortInfoComponentActive$.subscribe((shortinfo) => {
            if (shortinfo && this.selectedId && shortinfo.getId() !== this.selectedId) {
                this.asyncSelected = '';
                this.selectedId = null;
            }
        });
    }

    /**
     * Adressen sollen zuerst angezeigt werden, wenn ein Straßenname angegeben wird und es auch keinen Ort gibt, der so anfängt
     *
     * @param {string} query
     * @param {Array} addresses
     * @param {Array} places
     * @returns {boolean}
     */
    private showAdressesBeforePlaces(query: string, addresses: any[], places: any[]): boolean {
        let normalize = (str: string) => {
            str = str.toLowerCase();
            str = str.replace(/stra?(ß|ss)?e?/gi, 'str');
            str = str.replace(/^\s/, '');
            str = str.replace(/\s+$/, '');
            return str;
        };

        let addressHasExactMatch = false,
            placeHasExactMatch = false,
            queryLc = normalize(query);

        places.slice(0, 5).forEach((entry) => {
            if (normalize(entry.name).indexOf(queryLc) == 0) {
                placeHasExactMatch = true;
            }
        });
        addresses.slice(0, 3).forEach((entry) => {
            if (normalize(entry.name).indexOf(queryLc) == 0) {
                addressHasExactMatch = true;
            }
        });

        return (addressHasExactMatch && !placeHasExactMatch);
    }

    private calculateResults(query: string, placesOrLast: any, addressAutosuggests: any) {
        this._appState.closePrettyMuchEverything();

        let newresults = {
            entries: [],
            categories: placesOrLast.categories
        };

        if (placesOrLast.lastSearches && placesOrLast.lastSearches.length > 0) {
            newresults.entries.push(new TypeaheadMatch(null, 'Letzte Suchen', true, 'head'));
            placesOrLast.lastSearches.forEach((entry) => {
                newresults.entries.push(entry);
            });
        } else if (addressAutosuggests.length > 0) {
            let showAddressesBefore = this.showAdressesBeforePlaces(query, addressAutosuggests, placesOrLast.entries);
            if (showAddressesBefore) {
                newresults.entries.push(new TypeaheadMatch(null, 'Adressen', true, 'head'));
                addressAutosuggests.slice(0, 3).forEach((entry) => {
                    newresults.entries.push(entry);
                });
            }

            if (placesOrLast.entries.length > 0) {
                newresults.entries.push(new TypeaheadMatch(null, 'Suchergebnisse', true, 'head'));
            }
            placesOrLast.entries.slice(0, 5).forEach((entry) => {
                newresults.entries.push(entry);
            });

            if (!showAddressesBefore) {
                newresults.entries.push(new TypeaheadMatch(null, 'Adressen', true, 'head'));
                addressAutosuggests.slice(0, 3).forEach((entry) => {
                    newresults.entries.push(entry);
                });
            }
        } else {
            if (placesOrLast.entries.length > 0) {
                newresults.entries.push(new TypeaheadMatch(null, 'Suchergebnisse', true, 'head'));
            }
            placesOrLast.entries.slice(0, 7).forEach((entry) => {
                newresults.entries.push(entry);
            });
        }
        return newresults;
    }

    public changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
    }

    public changeTypeaheadNoResults(e: boolean): void {
        this.typeaheadNoResults = e;
    }

    public typeaheadOnSelect(e: TypeaheadMatch): void {
        this.selectedId = null;
        if (e.item.type == 'category') {
            this._appState.setCategorySelected(e.item.id);
        } else if (e.item.type == 'last-search') {
            if (e.item.item.getClassType() == 'place') {
                this._appState.openPlaceInShortInfo(e.item.item);
                this.selectedId = e.item.item.getId();
            }
            if (e.item.item.getClassType() == 'address') {
                this._globalMapActions.triggerSetCenterZoom(e.item.item.getPosition(), 16);
            }
        } else if (e.item.type == 'address-autosuggest') {
            let item: AddressAutosuggestEntry = <AddressAutosuggestEntry> e.item.item;
            this._lastSearches.addAddressEntry(item);
            this._appState.openPlaceInShortInfo(e.item.item);
            this.selectedId = e.item.item.getId();
            //this._globalMapActions.triggerSetCenterZoom(item.getPosition(), 16);
        } else {
            this._lastSearches.addPlacesEntry(e.item.item);
            this._appState.openPlaceInShortInfo(e.item.item);
            this.selectedId = e.item.item.getId();
        }
    }

    public toggleSidebar() {
        this._appState.toggleSidebar();
    }

    public clearInput() {
        this.asyncSelected = '';
        this._appState.setCategorySelected(DEFAULT_CATEGORY);
        this.selectedId = null;
        window.setTimeout(() => {
            let el: any = document.querySelector(".search-holder input");
            el.focus()
        }, 0);
    }

    ngAfterViewInit(): void {
        this._globalMapActions.centerLngLat$.subscribe((location: LngLat) => {
            this.mapCenter = location;
        });
        this._routingService.active$.subscribe((active: RoutingMode) => {
            this.hidden = (active == RoutingMode.FULLSCREEN);
        });
    }

    public onGotoCurrentPosition(): void {
        this._globalMapActions.triggerSearchMyPos();
    }
}
