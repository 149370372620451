import {NgModule, enableProdMode}      from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';

import {AppComponent}   from './components/app.component';
import {Ng2BootstrapModule} from './ng2-bootstrap';
import {SearchComponent} from "./components/search.component";
import {ShortInfoComponent} from "./components/short-info.component";
import {SidebarComponent} from "./components/sidebar.component";

import {MapStateService} from "./services/map-state.service";
import {MvgRoutingService} from "./apis/mvg-routing.service";
import {MapMVVLayerService} from "./map-layers/map-mvv.layer.service";
import {MapRoutingLayerService} from "./map-layers/map-routing.layer.service";
import {MapPlacesLayerService} from "./map-layers/map-places.layer.service";
import {EchtzeitproxyService} from "./apis/echtzeitproxy";
import {MapTilesService} from "./map-layers/map-tiles.service";
import {BikeSharingLayerService} from "./map-layers/map-bikesharing.layer.service";
import {URLService} from "./services/url.service";
import {ElasticsearchPlacesService} from "./apis/elasticsearch-places.service";
import {LastSearchesService} from "./services/last-searches.service";
import {RoutingPanelComponent} from "./components/routing-panel.component";
import {MVVRoutingService} from "./apis/mvv-routing.service";
import {MVGRoutingTransportationsComponent} from "./components/mvg-routing-transportations.component";
import {DurationPipe} from "./pipes/DurationPipe";
import {AddressAutosuggestService} from "./apis/address-autosuggest.service";
import {SettingsService} from "./services/settings.service";
import {CarSharingLayerService} from "./map-layers/map-carsharing.layer.service";
import {SpeechService} from "./services/speech.service";
import {RoutingTypeaheadComponent} from "./components/routing-typeahead.component";
import {OfflineCachingService} from "./services/offline-caching.service";
import {PlaceDetailsComponent} from "./components/place-details.component";
import {WalkingRouteService} from "./services/walking-route.service";
import {MuenchenBuildingsService} from "./apis/buildings.service";
import {HttpClientModule, HttpClientJsonpModule} from "@angular/common/http";
import {EpBoundingboxLoaderService} from "./services/ep-boundingbox-loader.service";
import {RelativeTimePipe} from "./pipes/RelativeTimePipe";
import {AccessibilityHintComponent} from "./components/accessibility-hint.component";
import {RoutingSwitcherComponent} from "./components/routing-switcher.component";
import {ShortInfoMobilityStationComponent} from "./components/short-info-mobility-station.component";
import {ShortInfoMvvDeparturesComponent} from "./components/short-info-mvv-departures.component";
import {ShortInfoChargersComponent} from "./components/short-info-chargers.component";
import {ChargingStationsLayerService} from "./map-layers/map-charging-stations.layer.service";
import {RoutingService} from "./services/routing.service";
import {ShortInfoBikeStationComponent} from "./components/short-info-bike-station.component";
import {ShortInfoStattautoComponent} from "./components/short-info-stattauto.component";
import {MVGStateService} from "./services/mvg-state.service";
import {AppStateService} from "./services/app-state.service";
import {TaxiLayerService} from "./map-layers/map-taxi.layer.service";
import {MapGlComponent} from './components/map-gl.component';
import {MyLocationLayerService} from './map-layers/my-location.layer.service';
import {EScooterLayerService} from './map-layers/map-escooter.layer.service';
import {RoutingTouristicComponent} from "./components/routing-touristic.component";
import {ElasticsearchService} from './services/elasticsearch.service';


if (location.href.indexOf("https") == 0) {
    enableProdMode();
}

@NgModule({
    imports: [BrowserModule, Ng2BootstrapModule, HttpClientJsonpModule, HttpClientModule, HammerModule],
    declarations: [
        // Components
        MapGlComponent,
        AppComponent, SearchComponent, ShortInfoComponent, SidebarComponent,
        RoutingPanelComponent, RoutingTypeaheadComponent, MVGRoutingTransportationsComponent,
        PlaceDetailsComponent,
        AccessibilityHintComponent,
        RoutingTouristicComponent,
        ShortInfoMobilityStationComponent,
        ShortInfoMvvDeparturesComponent,
        ShortInfoBikeStationComponent,
        ShortInfoStattautoComponent,
        ShortInfoChargersComponent,
        RoutingSwitcherComponent,

        // Pipes
        DurationPipe,
        RelativeTimePipe,
    ],
    bootstrap: [AppComponent],
    providers: [
        // State
        MapStateService,
        LastSearchesService, SettingsService, URLService, SpeechService, OfflineCachingService,
        EpBoundingboxLoaderService,
        RoutingService,
        MVGStateService,
        AppStateService,

        // APIs
        MvgRoutingService, AddressAutosuggestService, MapTilesService,
        ElasticsearchPlacesService, EchtzeitproxyService, MVVRoutingService, WalkingRouteService,
        MuenchenBuildingsService, ElasticsearchService,

        // Layers
        MyLocationLayerService,
        MapMVVLayerService, MapRoutingLayerService,
        MapPlacesLayerService,
        BikeSharingLayerService, CarSharingLayerService,
        ChargingStationsLayerService,
        TaxiLayerService,
        EScooterLayerService,
    ]
})
export class AppModule {
    constructor() {
    }
}
