import {PlacesDetails} from "./PlacesDetails";

export class PlacesDetailsCar extends PlacesDetails {
    public getVendorName() {
        let name = 'CarSharing';
        this.categories.forEach((cat) => {
            if (cat.id === 'mv.car.stattauto') {
                name = 'STATTAUTO Station';
            }
            if (cat.id === 'mv.car.stattautoflexy') {
                name = 'STATTAUTO Flexy';
            }
            if (cat.id === 'mv.car.drivenow') {
                name = 'DriveNow';
            }
            if (cat.id === 'mv.car.beezero') {
                name = 'Beezero';
            }
            if (cat.id === 'mv.car.car2go') {
                name = 'Car2Go';
            }
        });
        return name;
    }

    public getModelName(): string {
        if (this.payload && this.payload.carType) {
            return this.payload.carType;
        } else {
            return '';
        }
    }

    public getCarID(): string {
        if (this.payload && this.payload.licensePlate) {
            return this.payload.licensePlate;
        } else {
            return '';
        }
    }
}
