import {PlacesEntry} from "./PlacesEntry";
import {PlaceImage, PlaceInlineCategory} from "../interfaces";
import { LngLat } from 'mapbox-gl';

// Wird von muenchen-places.service.ts verwendet; ein bisschen Obfuscation
export function gk() {
    return 'signature';
}

export interface OpeningHours {
    day: string;
    from: string;
    to: string;
    status: string;
}

export class PlacesDetails {
    constructor(public position: LngLat,
                public mhpLink: string,
                public id: string,
                public names: { [language: string]: string },
                public iconUrlBase: string,
                public images: { [imageType: string]: PlaceImage[] },
                public cities: { [language: string]: string },
                public street: string,
                public descriptions: { [language: string]: string },
                private openingHours: OpeningHours[],
                public categories: PlaceInlineCategory[],
                public payload: any = null) {
    }

    public getId(): string {
        return this.id;
    }

    public getImage(): string {
        if (this.images['listPictures178'] && this.images['listPictures178'].length > 0) {
            return this.images['listPictures178'][0].url;
        }
        if (this.images['listPictures750'] && this.images['listPictures750'].length > 0) {
            return this.images['listPictures750'][0].url;
        }
        return null;
    }

    public getCity(language: string = "de") {
        if (this.cities[language]) {
            return this.cities[language];
        } else {
            for (let i in this.cities) {
                if (this.cities.hasOwnProperty(i)) {
                    return this.cities[i];
                }
            }
        }
        return "";
    }

    public getDescription(language: string = "de") {
        if (!this.descriptions) {
            return "";
        }
        if (this.descriptions[language]) {
            return this.descriptions[language];
        } else {
            for (let i in this.descriptions) {
                if (this.descriptions.hasOwnProperty(i)) {
                    return this.descriptions[i];
                }
            }
        }
        return "";
    }

    public getName(language: string = "de") {
        if (this.names[language]) {
            return this.names[language];
        } else {
            for (let i in this.names) {
                if (this.names.hasOwnProperty(i)) {
                    return this.names[i];
                }
            }
        }
        return "";
    }

    public getShortInfoTitle() {
        return this.getName();
    }

    public hasLanguage(language: string): boolean {
        if (this.names) {
            return (this.names[language] !== undefined);
        }
    }

    public hasOpeningHours(): boolean {
        return this.openingHours.length > 0;
    }

    public getOpeningHoursToday(): OpeningHours[] {
        let day = ["so", "mo", "di", "mi", "do", "fr", "sa"][(new Date()).getDay()];
        let hours: OpeningHours[] = [];
        this.openingHours.forEach((hour) => {
            if (hour.day == day) {
                hours.push(hour);
            }
        });
        return hours;
    }

    public getOpeningHoursFormatted(): string {
        if (!this.hasOpeningHours()) {
            return '';
        }
        return this.getOpeningHoursToday().map((open: OpeningHours) => {
            return open.from + " - " + open.to;
        }).join(", ");
    }

    public toNonDetailedEntry(): PlacesEntry {
        return new PlacesEntry(
            this.position,
            this.mhpLink,
            this.id,
            this.getName(),
            true,
            this.iconUrlBase,
            this.images,
            this.getCity(),
            this.street,
            this.categories
        );
    }

    // Wird von muenchen-places.service.ts verwendet; ein bisschen Obfuscation
    // 1. Teil des Keys rot13
    public static K1(): string {
        return 's57562483';
    }
}
