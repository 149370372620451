import {IRoutable} from "./IRoutable";
import { LngLat } from 'mapbox-gl';

export class MyLocation implements IRoutable {
    //public static DEFAULT_LOCATION = new LngLat(11.576006, 48.137079); // Marienplatz
    public static DEFAULT_LOCATION = new LngLat(11.4355147, 48.1497092); // Stadtteillabor
    public static DEFAULT_NAME = null;
    public static setDefaultLocation(newPos: LngLat, name: string) {
        MyLocation.DEFAULT_LOCATION = newPos;
        MyLocation.DEFAULT_NAME = name;
    }

    public static currMyLocationObject: MyLocation = null;

    constructor(private location: LngLat, public isFallback) {
        if (this.location === null) {
            this.location = MyLocation.DEFAULT_LOCATION;
        }
        MyLocation.currMyLocationObject = this;
    }

    getId(): string {
        return "my_location";
    }

    getPosition(): LngLat {
        return this.location;
    }

    getRoutingTitle(language: string): string {
        if (this.isFallback) {
            if (MyLocation.DEFAULT_NAME !== null) {
                return MyLocation.DEFAULT_NAME;
            } else if (language == 'de') {
                return "Mein Standort (nicht ermittelbar)";
            } else {
                return "My location (not available)";
            }
        } else {
            if (language == 'de') {
                return "Mein Standort";
            } else {
                return "My location";
            }
        }
    }

    getClassType(): string {
        return 'my_location';
    }

    public needsRoutingPlaceholderIcon() {
        return true;
    }

    getMvgRoutingString(): string {
        return this.getPosition().lat + "," + this.getPosition().lng;
    }
}
