import {Messages} from "./messages";

export const MESSAGES_DE: Messages =
    {
        language: "de",
        search: {
            none: "Nichts gefunden",
            placeholder: "Was suchen Sie?",
        },
        sidebar: {
            title_tiles: "Kartenmodus",
            title_options: "Darstellungsoptionen",
            layer_mvv: "Nahverkehr",
            layer_rad: "MVG Rad",
            layer_escooter: "E-Scooter",
            layer_tour: "Tour-Vorschlag",
            layer_charging_stations: "E-Ladesäulen",
            tile_basis: "Kartenansicht",
            tile_hausnummern: "Hausnummern",
            tile_luftbild: "Luftbild",
            muc_tourism: "Tourismusportal",
            muc_portal: "muenchen.de",
            accessible: "Barrierefrei",
            stele_accessible: "Orientierungsstele",
            tourism_accessible: "Tourismus",
            impressum: "Impressum",
            privacy: "Datenschutz",
            accessibility_hint_text: "Diese Seite gibt es auch barrierefrei in leichter Sprache und Audiodeskription.",
            accessibility_hint_link: "Zur barrierefreien Information",
        },
        routing: {
            title: "Route berechnen",
            from: "Start",
            from_placeholder: "Startpunkt auswählen",
            to: "Ziel",
            to_placeholder: "Ziel auswählen",
            public_title: "ÖPNV-Verbindungen",
            departure: "Abfahrt",
            calculating_route: "Berechne die Route...",
            autosuggest: "Vorschläge",
            show_route: "Route anzeigen",
        },
        shortinfo: {
            time_rel_hours: "Std.",
            time_rel_minutes: "Min.",
            opening_hours: "Heute geöffnet",
            bike_free: 'MVG Rad',
            bike_station: 'MVG Radstation',
            pedelec_station: 'MVG eRadstation',
            etrike_station: 'MVG eTrikestation',
            bike_no: 'MVG Rad-Nr.',
            bikes_available_pre: 'Noch',
            bikes_available: 'MVG Räder verfügbar',
            bike_available: 'MVG Rad verfügbar',
            pedelec_no: 'MVG eRad-Nr.',
            pedelecs_available_pre: 'Noch',
            pedelecs_available: 'MVG eRäder verfügbar',
            pedelec_available: 'MVG eRad verfügbar',
            etrike_no: 'MVG eTrike-Nr.',
            etrikes_available_pre: 'Noch',
            etrikes_available: 'MVG eTrikes verfügbar',
            etrike_available: 'MVG eTrike verfügbar',
            departures_loading: "Lade Abfahrtzeiten...",
            departures_next: "Nächste Abfahrten",
            departure_in: "in",
            stattauto_loading: "Lade Stattautos...",
            mobility_tab_public: "ÖPNV",
            mobility_tab_bike: "MVG Rad",
            mobility_tab_charging: "E-Ladesäule",
            mobility_tab_car: "Carsharing",
            mobility_tab_districtbox: "Quartiersbox",
            mobility_bike_available_1: "1 Rad verfügbar",
            mobility_bike_available_x: "Räder verfügbar",
            mobility_bike_order: "Rad reservieren",
            mobility_charging_order: "Jetzt laden",
            mobility_car_order: "Beim Anbieter buchen",
            charging_not_available: "Nicht verfügbar",
        },
        details: {
            back: 'Zurück',
            loading: 'Lade',
            open_website: 'Website',
            open_mvg: 'MVG Anfahrt',
            open_telephone: 'Telefon',
            open_photoupload: 'Foto-Upload',
        }
    };
