<div class="screen-darkener" *ngIf="expanded" [class.visible]="expanded" (click)="closeDetails()"></div>

<aside class="short-info-box-list" [class.visible]="visible"
       [class.mt_site]="activeContent?.getClassType() == 'mt_site'"
       [class.bike]="activeContent?.getClassType() == 'bike'"
       [class.place]="activeContent?.getClassType() == 'place'"
       [class.mvg_station]="activeContent?.getClassType() == 'mvg_station'"
       [class.mobility_station]="activeContent?.getClassType() == 'mobility_station'"
       [class.charging_station]="activeContent?.getClassType() == 'charging_station'"
       [class.erdgas]="activeContent?.getClassType() == 'erdgas'"
       [class.car]="activeContent?.getClassType() == 'car'"
       [class.expanded]="expanded"
       (panleft)="panLeft($event)" (panright)="panRight($event)" (panend)="panEnd($event)">
    <section class="short-info-box-holder carousel-prev">
        <ng-template [ngTemplateOutlet]="getTemplate(prevEntry)"
                     [ngTemplateOutletContext]="{ $implicit: prevEntry, iamexpanded: false, isprimary: false }"></ng-template>
    </section>
    <section class="short-info-box-holder carousel-current">
        <ng-template [ngTemplateOutlet]="getTemplate(activeContent)"
                     [ngTemplateOutletContext]="{ $implicit: activeContent, iamexpanded: expanded, isprimary: true }"></ng-template>
    </section>
    <section class="short-info-box-holder carousel-next">
        <ng-template [ngTemplateOutlet]="getTemplate(nextEntry)"
                     [ngTemplateOutletContext]="{ $implicit: nextEntry, iamexpanded: false, isprimary: false }"></ng-template>
    </section>
</aside>

<ng-template #placeTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box place info-box-place" [class.expanded]="iamexpanded" [class.not-expanded]="!iamexpanded">
        <section *ngIf="!iamexpanded" class="short-info-box place">
            <div class="data">
                <div class="logo-holder">
                    <a [href]="entry?.mhpLink" target="_blank" rel="noopener" class="logo-link">
                        <img *ngIf="entry?.getShortInfoImage()" [src]="entry?.getShortInfoImage()"
                             [alt]="entry?.getShortInfoTitle() + ' Abbildung'" class="logo">
                        <img *ngIf="!entry?.getShortInfoImage() && entry.needsFallbackImage()" src="images/fallback_image.jpg"
                             alt="Keine Abbildung vorhanden" class="logo logo-fallback">
                    </a>
                </div>
                <a [href]="entry?.mhpLink" target="_blank" rel="noopener" class="detail-link">
                    <h2>
                        {{ entry?.getShortInfoTitle() }}
                    </h2>
                    <div class="opening-hours" *ngIf="isprimary && activePlaceDetails?.hasOpeningHours()">
                        <img src="images/Icon-Uhr.svg" alt="Öffnungszeiten">
                        <span class="opening-hours-label">{{messages.opening_hours}}</span>
                        <span class="opening-hours-time">{{ activePlaceDetails?.getOpeningHoursFormatted() }}</span>
                    </div>
                </a>
                <routing-switcher (change)="routingChanged()"></routing-switcher>
            </div>
        </section>
        <place-details *ngIf="iamexpanded" [placeDetails]="placeDetails" (close)="closeDetails()"></place-details>
    </article>
</ng-template>

<ng-template #eScooterTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-bike not-expanded">
        <section class="short-info-box short-info-box-escooter">
            <div class="data">
                <img src="images/logo_tier.png" alt="TIER Logo" class="logo">
                <h2>{{ activePlaceDetailsEScooter?.getShortInfoTitle() }}</h2>
                <div class="battery-level" *ngIf="isprimary">
                    <img src="images/batteriefuellstand-01.svg" alt="Batteriefüllstand" class="batterie">
                    {{ activePlaceDetailsEScooter?.getBatteryLevel() }}%
                </div>
            </div>
        </section>
    </article>
</ng-template>

<ng-template #bikeFreefloaterTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-bike not-expanded">
        <section class="short-info-box short-info-box-bike short-info-box-bike-freefloater">
            <div class="data">
                <img *ngIf="entry?.getShortInfoImage()" [src]="entry?.getShortInfoImage()"
                     [alt]="entry?.getShortInfoTitle() + ' Abbildung'" class="logo">
                <h2>{{ messages.bike_free }}</h2>
                <div class="bike-id">
                    {{ messages.bike_no }} {{ activePlaceDetailsBike?.getBikeId() }}
                </div>
            </div>
        </section>
    </article>
</ng-template>

<ng-template #bikeStationTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-bike not-expanded">
        <section class="short-info-box short-info-box-bike short-info-box-bike-station">
            <div class="data">
                <img *ngIf="entry?.getShortInfoImage()" [src]="entry?.getShortInfoImage()"
                     [alt]="entry?.getShortInfoTitle() + ' Abbildung'" class="logo">
                <h2>{{ messages.bike_station }}</h2>
                <div class="address" [innerHtml]="entry?.getShortInfoTitle()"></div>
                <div class="available">
                    {{ activePlaceDetailsBike?.getStationAvailable() }}
                    <span *ngIf="activePlaceDetailsBike?.getStationAvailable() == 1">{{ messages.bike_available }}</span>
                    <span *ngIf="activePlaceDetailsBike?.getStationAvailable() != 1">{{ messages.bikes_available }}</span>
                </div>
            </div>
        </section>
    </article>
</ng-template>

<ng-template #pedelecTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-bike not-expanded">
        <section class="short-info-box short-info-box-bike short-info-box-bike-station">
            <div class="data">
                <img *ngIf="entry?.getShortInfoImage()" [src]="entry?.getShortInfoImage()"
                     [alt]="entry?.getShortInfoTitle() + ' Abbildung'" class="logo">
                <h2>{{ messages.pedelec_station }}</h2>
                <div class="address" [innerHtml]="entry?.getShortInfoTitle()"></div>
                <div class="available">
                    {{ activePlaceDetailsBike?.getStationAvailable() }}
                    <span *ngIf="activePlaceDetailsBike?.getStationAvailable() == 1">{{ messages.pedelec_available }}</span>
                    <span *ngIf="activePlaceDetailsBike?.getStationAvailable() != 1">{{ messages.pedelecs_available }}</span>
                </div>
            </div>
        </section>
    </article>
</ng-template>

<ng-template #etrikeTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-bike not-expanded">
        <section class="short-info-box short-info-box-bike short-info-box-bike-station">
            <div class="data">
                <img *ngIf="entry?.getShortInfoImage()" [src]="entry?.getShortInfoImage()"
                     [alt]="entry?.getShortInfoTitle() + ' Abbildung'" class="logo">
                <h2>{{ messages.etrike_station }}</h2>
                <div class="address" [innerHtml]="entry?.getShortInfoTitle()"></div>
                <div class="available">
                    {{ activePlaceDetailsBike?.getStationAvailable() }}
                    <span *ngIf="activePlaceDetailsBike?.getStationAvailable() == 1">{{ messages.etrike_available }}</span>
                    <span *ngIf="activePlaceDetailsBike?.getStationAvailable() != 1">{{ messages.etrike_available }}</span>
                </div>
            </div>
        </section>
    </article>
</ng-template>

<ng-template #carTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-car not-expanded">
        <section class="short-info-box">
            <div class="data">
                <img *ngIf="entry?.getShortInfoImage()" [src]="entry?.getShortInfoImage()"
                     [alt]="entry?.getShortInfoTitle() + ' Abbildung'" class="logo">
                <h2>{{ activePlaceDetailsCar?.getVendorName() }}</h2>
                <div class="model" [innerHtml]="activePlaceDetailsCar?.getModelName()"></div>
                <div class="license" [innerHtml]="activePlaceDetailsCar?.getCarID()"></div>
                <div class="action">
                    <a href="">Buchung anfragen</a>
                </div>
            </div>
        </section>
    </article>
</ng-template>

<ng-template #stattautoTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-car info-box-stattauto not-expanded">
        <section class="short-info-box short-info-box-stattauto">
            <img *ngIf="entry?.getShortInfoImage()" [src]="entry?.getShortInfoImage()"
                 [alt]="entry?.getShortInfoTitle() + ' Abbildung'" class="logo">
            <h2>{{ entry?.getShortInfoTitle() }}</h2>

            <short-info-stattauto [station]="entry"></short-info-stattauto>
        </section>
    </article>
</ng-template>

<ng-template #mobilityStationTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-mobility-station not-expanded">
        <short-info-mobility-station [station]="entry"></short-info-mobility-station>
    </article>
</ng-template>

<ng-template #chargingStationTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-charging-station not-expanded mvg-entry-list">
        <h2>{{ entry?.getShortInfoTitle() }}</h2>
        <short-info-chargers [stations]="[placeDetails]" *ngIf="placeDetails"></short-info-chargers>
    </article>
</ng-template>

<ng-template #erdgasTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box info-box-erdgas not-expanded">
        <section class="short-info-box">
            <div class="data">
                <h2>{{ placeDetails?.getName() }}</h2>
                ... Erdgas
            </div>
        </section>
    </article>
</ng-template>

<ng-template #entryTmpl let-entry let-iamexpanded="iamexpanded" let-isprimary="isprimary">
    <article class="info-box" [class.expanded]="iamexpanded" [class.not-expanded]="!iamexpanded">
        <section *ngIf="!iamexpanded" class="short-info-box">
            <div class="data">
                <img *ngIf="entry?.getShortInfoImage()" [src]="entry?.getShortInfoImage()"
                     [alt]="entry?.getShortInfoTitle() + ' Abbildung'" class="logo">
                <div class="call-to-actions">
                    <button class="btn btn-link btn-routing" (click)="callRouting(entry)" title="Routenplaner">
                        <img src="images/Icon-Route.svg" alt="Routing">
                    </button>
                </div>
                <h2>{{ entry?.getShortInfoTitle() }}</h2>
                <div class="address" [innerHtml]="entry?.getShortInfoAddress()"></div>
            </div>
        </section>
    </article>
</ng-template>

<ng-template #mvgTmpl let-entry let-isprimary="isprimary">
    <article class="info-box">
        <section class="short-info-box short-info-box-mvg">
            <short-info-mvv-departures [station]="entry" [minimalistic]="false"></short-info-mvv-departures>
        </section>
    </article>
</ng-template>
