import {Injectable} from "@angular/core";
import {Observable} from 'rxjs';
import {RouteDefinition, RoutingTransportation} from "../interfaces";
import {IRoutable} from "../classes/IRoutable";
import {WalkingRouteService} from "../services/walking-route.service";
import {SightseeingRoute} from "../classes/SightseeingRoute";
import {PlacesEntry} from "../classes/PlacesEntry";
import {HttpClient, HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";


@Injectable()
export class MvgRoutingService {
    private url = 'https://muenchenapis.de/proxies/metarouter/v1/proxy.php';

    constructor(private http: HttpClient, private _walkingrouting: WalkingRouteService) {
    }

    public static defaultOptions(options): any {
        options = options || {};
        options['precision'] = options['precision'] || 5;
        options['factor'] = options['factor'] || Math.pow(10, options['precision']);
        options['dimension'] = options['dimension'] || 2;
        return options;
    }

    public static decodeDeltas(encoded, options) {
        options = MvgRoutingService.defaultOptions(options);

        let lastNumbers = [];

        let numbers = MvgRoutingService.decodeFloats(encoded, options);
        for (let i = 0, len = numbers.length; i < len;) {
            for (let d = 0; d < options.dimension; ++d, ++i) {
                numbers[i] = Math.round((lastNumbers[d] = numbers[i] + (lastNumbers[d] || 0)) * options.factor) / options.factor;
            }
        }

        return numbers;
    }

    public static decodeFloats(encoded, options) {
        options = MvgRoutingService.defaultOptions(options);

        let numbers = MvgRoutingService.decodeSignedIntegers(encoded);
        for (let i = 0, len = numbers.length; i < len; ++i) {
            numbers[i] /= options.factor;
        }

        return numbers;
    }

    public static decodeSignedIntegers(encoded) {
        let numbers = MvgRoutingService.decodeUnsignedIntegers(encoded);

        for (let i = 0, len = numbers.length; i < len; ++i) {
            let num = numbers[i];
            numbers[i] = (num & 1) ? ~(num >> 1) : (num >> 1);
        }

        return numbers;
    }

    public static decodeUnsignedIntegers(encoded) {
        let numbers = [];

        let current = 0;
        let shift = 0;

        for (let i = 0, len = encoded.length; i < len; ++i) {
            let b = encoded.charCodeAt(i) - 63;

            current |= (b & 0x1f) << shift;

            if (b < 0x20) {
                numbers.push(current);
                current = 0;
                shift = 0;
            } else {
                shift += 5;
            }
        }

        return numbers;
    }

    public static decodePolyline(encoded, options): any {
        options = MvgRoutingService.defaultOptions(options);

        let flatPoints = MvgRoutingService.decodeDeltas(encoded, options);

        let points = [];
        for (let i = 0, len = flatPoints.length; i + (options.dimension - 1) < len;) {
            let point = [];

            for (let dim = 0; dim < options.dimension; ++dim) {
                point.push(flatPoints[i++]);
            }

            // [Lat, Lng] => [Lng, Lat]
            points.push([point[1], point[0]]);
        }

        return points;
    }

    getSightseeingRoute(from: IRoutable, to: IRoutable): Promise<RouteDefinition> {
        let sightseeing: SightseeingRoute;
        return this._walkingrouting.getBestWalkingTour(from, to)
            .then((sightseeingRoute: SightseeingRoute) => {
                sightseeing = sightseeingRoute;

                const routes: Promise<RouteDefinition>[] = [];
                let lastPlace = from;
                sightseeingRoute.inbetweenPlaces.forEach(inPlace => {
                    routes.push(this.getRoutePromise(lastPlace, inPlace));
                    lastPlace = inPlace;
                });
                routes.push(this.getRoutePromise(lastPlace, to));
                return Promise.all(routes);
            }).then((routes: RouteDefinition[]) => {
                let length = 0;
                let duration = 0;
                let polyline: [number, number][] = [];
                routes.forEach(route => {
                    length += route.length;
                    duration += route.duration;
                    polyline = [...polyline, ...route.overview_polyline];
                });

                return {
                    from: from,
                    to: to,
                    start_address: from.getRoutingTitle("de"),
                    end_address: to.getRoutingTitle("de"),
                    overview_polyline: polyline,
                    length: length,
                    duration: duration,
                    hints: '',
                    hasPublicTransportation: false,
                    transportationType: RoutingTransportation.SIGHTSEEING,
                    inbetweenPlaces: sightseeing.inbetweenPlaces
                }
            });
    }

    public getRoute(from: IRoutable, to: IRoutable,
             transportation: RoutingTransportation = RoutingTransportation.WALKING): Observable<RouteDefinition> {

        let params = new HttpParams()
            .set('originLat', from.getPosition().lat.toString(10))
            .set('originLng', from.getPosition().lng.toString(10))
            .set('destinationLat', to.getPosition().lat.toString(10))
            .set('destinationLng', to.getPosition().lng.toString(10));
        switch (transportation) {
            case RoutingTransportation.WALKING:
                params = params.set('modalities', 'walk');
                break;
            case RoutingTransportation.BIKE:
                params = params.set('modalities', 'bike');
                break;
            case RoutingTransportation.CAR:
                params = params.set('modalities', 'car');
                break;
        }

        return this.http.get(this.url, {params: params}).pipe(map((body: any): RouteDefinition => {
            let route = body['items'][0],
                firstLeg = route.legs[0],
                lastLeg = route.legs[route.legs.length - 1],
                hints: string[] = [],
                hasPublicTransportation: boolean = false,
                polylineEnc = firstLeg.polyline,
                polyline = MvgRoutingService.decodePolyline(polylineEnc, {});

            let distance = 0;
            let time = 0;
            route.legs.forEach(leg => {
                distance += leg.distanceInMeters;
                time += leg.travelTimeInSeconds;
                if (leg.type === 'public_transport') {
                    hasPublicTransportation = true;
                    const line = leg.line.vehicle.type + " " + leg.line.name;
                    const description = "von " + leg.origin.name + " bis " + leg.destination.name;
                    let desc = '<span class="instruction">' + line + '</span>';
                    desc += ' <span class="details">' + description + '</span>';
                    hints.push(desc);
                }
            });

            return {
                from: from,
                to: to,
                start_address: firstLeg.origin.name,
                end_address: lastLeg.destination.name,
                overview_polyline: polyline,
                length: distance,
                duration: time,
                hints: hints.join(", "),
                hasPublicTransportation: hasPublicTransportation,
                transportationType: transportation,
                inbetweenPlaces: []
            };
        }));
    }

    public getRoutePromise(from: IRoutable, to: IRoutable, transportation: RoutingTransportation = RoutingTransportation.WALKING): Promise<RouteDefinition> {
        return new Promise<RouteDefinition>(accept => {
            this.getRoute(from, to, transportation).subscribe((route: RouteDefinition) => {
                accept(route);
            })
        });
    }
}
