import {Injectable} from "@angular/core";
import {IShortInfoComponentContent} from "../classes/IShortInfoComponentContent";
import {EchtzeitproxyService} from "../apis/echtzeitproxy";
import {PlacesDetails} from "../classes/PlacesDetails";
import {PlacesEntry} from "../classes/PlacesEntry";
import {AddressAutosuggestEntry} from "../classes/AddressAutosuggestEntry";
import {AddressAutosuggestService} from "../apis/address-autosuggest.service";
import {Messages} from "../messages/messages";
import {MyLocation} from "../classes/MyLocation";
import {AppStateService} from "./app-state.service";
import { LngLat } from 'mapbox-gl';

interface URLState {
    category?: string;
    language?: string;
    place_limit?: number;
    place?: { type: string, id: string, subparts: string[] };
    tiles?: number;
    view: { center: LngLat, zoom: number, name: string };
}

@Injectable()
export class URLService {
    private hashPathComponent: IShortInfoComponentContent;
    private urlSubparts: string[] = [];
    private baseUrl;
    private currentState: URLState = {
        category: null,
        language: null,
        place_limit: null,
        place: null,
        tiles: null,
        view: null
    };

    private currAppLanguage: string;
    private url_aliases: { [url: string]: { center: LngLat, zoom: number, name: string } } = {};


    private shortInfoComponentActive: IShortInfoComponentContent;
    private shortInfoComponentExpanded: boolean;

    private shortlyAfterHashChange: boolean = false;

    constructor(private _placeLoader: EchtzeitproxyService,
                private _appState: AppStateService,
                private _addressLoader: AddressAutosuggestService) {
        this.baseUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;

        window['MAP_CONFIG']['STELEN'].forEach((obj: object) => {
            this.url_aliases[obj['url']] = {
                'center': new LngLat(obj['lng'], obj['lat']),
                'zoom': obj['zoom'],
                'name': obj['name'],
            };
        });

        this._appState.language$.subscribe((messages: Messages) => {
            this.currAppLanguage = messages.language;
        });

        this.currentState = this.parseUrl();
    }

    public getLanguage(): string {
        return 'de';
    }

    public getCurrentState(): URLState {
        return this.currentState;
    }

    private setUrl(replace: boolean) {
        if (this.shortlyAfterHashChange) {
            return;
        }

        let newUrl = this.baseUrl;
        if (this.hashPathComponent) {
            newUrl += '?' + this.hashPathComponent.getUrlHash(this.urlSubparts);
        }
        if (replace) {
            history.replaceState({}, '', newUrl);
        } else {
            history.pushState({}, '', newUrl);
        }
    }

    private parseUrl(): URLState {
        let state: URLState = this.currentState;

        // Werte, die hier nicht resettet werden, bleiben unverändert, wenn sie in der URL nicht vorkommen
        state.place = null;

        let hash = location.search;
        if (hash.length == 0 || hash[0] != '?') {
            return state;
        }
        hash = hash.substr(1);

        let parts: string[] = hash.split("/");

        for (let i = 0; i < parts.length; i++) {
            if (['place', 'address'].indexOf(parts[i]) > -1 && parts.length > i + 1) {
                let place = {
                    type: parts[i],
                    id: decodeURIComponent(parts[i + 1]),
                    subparts: [],
                };
                if (parts.length > i + 2 && parts[i + 2] == 'details') {
                    place.subparts.push(parts[i + 2]);
                    i++;
                }
                i++;
                state.place = place;
            }
            if (['de', 'en'].indexOf(parts[i]) > -1) {
                state.language = parts[i];
            }
            if (parts[i] == 'mt') {
                state.tiles = 5; // MTourismus
            }
            if (this.url_aliases[parts[i]] !== undefined) {
                state.view = this.url_aliases[parts[i]];
                MyLocation.setDefaultLocation(state.view.center, state.view.name);
            }
            if (parts[i] == 'location' && parts.length > i + 1) {
                let match = parts[i + 1].match(/^[\d\\.]+,[\d\\.]+(,\d+)?/ig);
                if (!match) {
                    continue;
                }

                let locparts: string[] = match[0].split(",");
                let zoom = 16,
                    pos: LngLat = new LngLat(parseFloat(locparts[1]), parseFloat(locparts[0]));
                if (locparts.length > 2 && parseInt(locparts[2]) > 0 && parseInt(locparts[2]) < 21) {
                    zoom = parseInt(locparts[2]);
                }
                state.view = {center: pos, zoom: zoom, name: null};
            }
            if (parts[i] == 'category' && parts.length > i + 1) {
                let category = parts[i + 1];
                console.log("Switch to category: ", category);
                state.category = category;
                this._appState.setCategorySelected(category);
            }
        }
        return state;
    }

    public hasInitialShortInfo(): boolean {
        return (this.currentState.place !== null)
    }

    public handleShortInfoUrl() {
        if (!this.currentState.place || !this.currentState.place.type) {
            this._appState.placeClose();
            return;
        }

        let elementPromise = this.getInitialShortInfo();
        if (elementPromise) {
            elementPromise.then((active: IShortInfoComponentContent) => {
                if ((this.shortInfoComponentActive && !active) || (!this.shortInfoComponentActive && active) ||
                    (this.shortInfoComponentActive && active && this.shortInfoComponentActive.getId() != active.getId())) {
                    this._appState.openPlaceInShortInfo(active);
                }

                let expanded = (this.currentState.place.subparts.indexOf('details') > -1);
                if (expanded != this.shortInfoComponentExpanded) {
                    this._appState.shortInfoComponentExpand(expanded);
                }
            });
        }
    }

    public removeMvv(): boolean {
        return false;
    }
    public forceMvv(): boolean {
        return false;
    }


    private loadPlaceEntry(id: string): Promise<PlacesEntry> {
        return new Promise<PlacesEntry>((resolve) => {
            this._placeLoader.loadDetails(id, this.currAppLanguage).subscribe((place: PlacesDetails) => {
                resolve(place.toNonDetailedEntry());
            });
        });
    }

    private loadAddressEntry(address: string): Promise<AddressAutosuggestEntry> {
        return new Promise<AddressAutosuggestEntry>((resolve) => {
            this._addressLoader.loadFirstAutosuggest(address).subscribe((address: AddressAutosuggestEntry) => {
                resolve(address);
            });
        });
    }

    public getInitialShortInfo(): Promise<IShortInfoComponentContent> {
        if (!this.currentState.place) {
            return null;
        }
        switch (this.currentState.place.type) {
            case 'place':
                return this.loadPlaceEntry(this.currentState.place.id);
            case 'address':
                return this.loadAddressEntry(this.currentState.place.id);
        }
        return null;
    }

    public setActiveShortInfo(info: IShortInfoComponentContent) {
        this.hashPathComponent = info;
        this.urlSubparts = [];
        this.setUrl(false);
    }

    public setActiveShortInfoExpanded(info: IShortInfoComponentContent) {
        this.hashPathComponent = info;
        this.urlSubparts = ['details'];
        this.setUrl(false);
    }

    public getInitialView(): { center: LngLat, zoom: number } {
        return this.currentState.view;
    }

    public rebuildUrlFromState() {
        if (this.shortInfoComponentActive) {
            if (this.shortInfoComponentExpanded) {
                this.setActiveShortInfoExpanded(this.shortInfoComponentActive);
            } else {
                this.setActiveShortInfo(this.shortInfoComponentActive);
            }
        } else {
            this.setActiveShortInfo(null);
        }
    }

    public initUrlListener() {
        window.addEventListener("hashchange", () => {
            this.shortlyAfterHashChange = true;
            window.setTimeout(() => {
                this.shortlyAfterHashChange = false;
            }, 100);

            this.currentState = this.parseUrl();
            console.log("New state", this.currentState);

            this.handleShortInfoUrl();
        });

        let shortInfoComponentActiveFirst = true;
        this._appState.shortInfoComponentActive$.subscribe((active) => {
            this.shortInfoComponentActive = active;
            if (shortInfoComponentActiveFirst) {
                shortInfoComponentActiveFirst = false;
            } else {
                this.rebuildUrlFromState();
            }
        });

        let shortInfoComponentExpandedFirst = true;
        this._appState.shortInfoComponentExpanded$.subscribe((expanded: boolean) => {
            this.shortInfoComponentExpanded = expanded;
            if (shortInfoComponentExpandedFirst) {
                shortInfoComponentExpandedFirst = false;
            } else {
                this.rebuildUrlFromState();
            }
        });
    }
}
