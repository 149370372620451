import { Component, Input } from '@angular/core';
import { PlacesDetails } from "../classes/PlacesDetails";
import { MapStateService } from "../services/map-state.service";
import { Messages, ShortInfoMessages } from "../messages/messages";
import { MVGStationDeparture, MVV_TYPE, PlaceInlineCategory } from "../interfaces";
import { EchtzeitproxyService } from "../apis/echtzeitproxy";
import { AppStateService } from "../services/app-state.service";

@Component({
    selector: 'short-info-mvv-departures',
    templateUrl: 'short-info-mvv-departures.component.html',
    styles: [],
})
export class ShortInfoMvvDeparturesComponent {
    private _station: PlacesDetails = null;
    @Input()
    set station(station: any) {
        this._station = station;
        this.initMvgStationContent();

        this.sortedPublicCats = this._station.categories.filter(cat => {
            return ['mv.public.ubahn', 'mv.public.sbahn', 'mv.public.tram', 'mv.public.bus'].indexOf(cat.id) !== -1;
        }).sort((cat1: PlaceInlineCategory, cat2: PlaceInlineCategory) => {
            const sort1 = this.catToSort(cat1);
            const sort2 = this.catToSort(cat2);
            if (sort1 < sort2) {
                return -1;
            } else if (sort1 > sort2) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    get station() {
        return this._station;
    };

    @Input() minimalistic: boolean = false;

    public messages: ShortInfoMessages = null;
    public currentLanguage: string = null;
    public mvgDepartures: MVGStationDeparture[] = null;
    public filteredDepartures: MVGStationDeparture[] = null;
    public mvgTypes = MVV_TYPE;
    public transportFilter: string = null;
    public sortedPublicCats: PlaceInlineCategory[] = [];

    private catToSort(cat: PlaceInlineCategory): number {
        switch (cat.id) {
            case 'mv.public.ubahn':
                return 1;
            case 'mv.public.bus':
                return 2;
            case 'mv.public.tram':
                return 3;
            case 'mv.public.sbahn':
                return 4;
            default:
                return 0;
        }
    };

    constructor(private _globalMapActions: MapStateService,
                private _ep: EchtzeitproxyService,
                private _appState: AppStateService) {
        this._appState.language$.subscribe((messages: Messages) => {
            this.messages = messages.shortinfo;
            this.currentLanguage = messages.language;
        });
    }


    public initMvgStationContent() {
        this.mvgDepartures = null;
        this.setFilteredDepartures();
        this._ep.loadStationDepartures(this.station.getId(), 20).subscribe((departures: MVGStationDeparture[]) => {
            departures = departures.filter(departure => {
                const timeInFuture = Math.round((departure.time.getTime() - (new Date()).getTime()) / 1000);
                return (timeInFuture > 60);
            });
            this.mvgDepartures = departures;
            this.setFilteredDepartures();
        });
    }

    public setFilteredDepartures() {
        if (this.transportFilter == null) {
            this.filteredDepartures = this.mvgDepartures;
        } else {
            this.filteredDepartures = this.mvgDepartures.filter((departures) => {
                switch (this.transportFilter) {
                    case 'sbahn':
                        return departures.type == this.mvgTypes.SBAHN;
                    case 'ubahn':
                        return departures.type == this.mvgTypes.UBAHN;
                    case 'tram':
                        return departures.type == this.mvgTypes.TRAM;
                    case 'bus':
                        return departures.type == this.mvgTypes.BUS;
                }
            });
        }
    }

    public toggleTransport(transport) {
        if (this.transportFilter == transport) {
            this.transportFilter = null;
        } else {
            this.transportFilter = transport;
        }
        this.setFilteredDepartures();
    }
}
