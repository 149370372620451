<aside id="routing-touristic-hint" *ngIf="show">
    <button class="btn-link btn-close" type="button" (click)="close()">
        <img src="images/Icon-50px-close.png" alt="Close">
    </button>

    <div class="content">
        <div class="icons">
            <img src="images/routing_sightseing_orange.svg" alt="Routing" width="50">
        </div>
        Dieses Icon führt Sie auf Ihrem Fußweg vorbei an Münchens an der Strecke liegenden Sehenswürdigkeiten und bietet Ihnen somit eine touristisch wertvolle Routenführung an.
    </div>
</aside>
