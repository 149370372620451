import {Component} from '@angular/core';
import {Messages, SidebarMessages} from "../messages/messages";
import {AppStateService} from "../services/app-state.service";

@Component({
    selector: 'accessibility-hint',
    templateUrl: 'accessibility-hint.component.html',
    styles: [],
})
export class AccessibilityHintComponent {
    public show: boolean;
    public messages: SidebarMessages = null;

    public constructor(private _appState: AppStateService) {
        this.show = !this.getShown();

        this._appState.language$.subscribe((messages: Messages) => {
            this.messages = messages.sidebar;
        });
    }

    public close() {
        this.setShown();
        this.show = false;
    }

    private getShown(): boolean {
        if (!localStorage) {
            return false;
        }
        let shown = localStorage.getItem('accessibility_hint_shown');
        if (shown === null) {
            return false;
        }
        return (shown === '1');
    }

    private setShown() {
        if (!localStorage) {
            return;
        }
        localStorage.setItem('accessibility_hint_shown', '1');
    }
}
