<div class="head">
    <ul class="type-list" *ngIf="station">
        <li [class.active]="bikeFilter == 'bikes'" *ngIf="station.hasBikes()">
            <img src="images/mvg_rad_station.png" alt="Station" (click)="toggleFilter('bikes')">
        </li>
        <li [class.active]="bikeFilter == 'etrikes'" *ngIf="station.hasEtrikes()">
            <img src="images/mvg_etrike.svg" alt="MVG eTrikes" (click)="toggleFilter('etrikes')">
        </li>
        <li [class.active]="bikeFilter == 'pedelecs'" *ngIf="station.hasPedelecs()">
            <img src="images/mvg_pedelec.svg" alt="MVG eRad" (click)="toggleFilter('pedelecs')">
        </li>
    </ul>
</div>
<ul class="bikes entry-list">
    <ng-container *ngIf="bikeFilter == null || bikeFilter == 'bikes'">
        <li *ngFor="let bike of station.getStaticBikes()">
            <div class="icon">
                <img src="images/mvg_rad_station.png" alt="Station">
            </div>
            <div class="title-availability">
                <div class="title">{{ messages.bike_station }}</div>
                <div class="availability">{{ getBikeAvailabilityStr(bike) }}</div>
            </div>
        </li>
    </ng-container>
    <ng-container *ngIf="bikeFilter == null || bikeFilter == 'etrikes'">
        <li *ngFor="let bike of station.getEtrikes()">
            <div class="icon">
                <img src="images/mvg_etrike.svg" alt="eTrikes">
            </div>
            <div class="title-availability">
                <div class="title">{{ messages.etrike_station }}</div>
                <div class="availability">{{ getBikeAvailabilityStr(bike) }}</div>
            </div>
        </li>
    </ng-container>
    <ng-container *ngIf="bikeFilter == null || bikeFilter == 'pedelecs'">
        <li *ngFor="let bike of station.getPedelecs()">
            <div class="icon">
                <img src="images/mvg_pedelec.svg" alt="Pedelecs">
            </div>
            <div class="title-availability">
                <div class="title">{{ messages.pedelec_station }}</div>
                <div class="availability">{{ getBikeAvailabilityStr(bike) }}</div>
            </div>
        </li>
    </ng-container>
    <ng-container *ngIf="bikeFilter == null || bikeFilter == 'bikes'">
        <li *ngFor="let bike of station.getVolatileBikes()">
            <div class="icon">
                <img src="images/pt_mvg_rad_freefloater.png" alt="Station">
            </div>
            <div class="title-availability">
                <div class="title">{{ messages.bike_free }} {{ bike.getBikeId() }}</div>
            </div>
        </li>
    </ng-container>
</ul>
